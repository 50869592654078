import React from 'react';
import Topbar from '../topbar/Topbar';
import Sidebar from '../sidebar/Sidebar';

const Layout = ({ children }) => {
  return (
    <>
      <Topbar />
      <div style={{ display: 'flex' }}>
      <Sidebar />
        <div style={{ flexGrow: 1 }}>
        
          {children}
        </div>
      </div>
    </>
  );
};

export default Layout;
