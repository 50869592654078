import React, { useState, useEffect, useContext } from 'react';
import "./moderatePost.css";
import { MoreVert, Delete } from "@mui/icons-material";
import S3Image from '../S3Image/S3Image';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { deleteCommunityPost, updateCommunityPost } from '../../graphql/mutations';
import { listGuidanceContents } from '../../graphql/queries';
import { UserContext } from '../../App';
import { Menu, MenuItem, IconButton, Snackbar, Dialog, DialogContent, DialogTitle, Button, DialogActions, DialogContentText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';

import { useNavigate } from 'react-router-dom';



export default function ModeratePost({ post: initialPost, removePost }) {
  const [post, setPost] = useState(initialPost);
  const [anchorEl, setAnchorEl] = useState(null);
  const userData = useContext(UserContext);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [guidanceContent, setGuidanceContent] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [actionType, setActionType] = useState('');



  const navigate = useNavigate();

  const navigateToUserProfile = (userId) => {
    //navigate(`/profile/${userId}`);
  };


  const handleGuidanceClick = async () => {
    //console.log(post.communityPostGuidanceId);
    const response = await API.graphql(
      graphqlOperation(listGuidanceContents, {
        filter: { guidanceID: { eq: post.communityPostGuidanceId } }
      })
    )
    const data = response.data.listGuidanceContents.items[0]; // Assuming the items hold the actual list
    //console.log(data);
    setGuidanceContent(data);

    setModalOpen(true);
  };

  useEffect(() => {

    if (post?.mediaS3Key && post?.mediaS3Key.endsWith('.mp4')) {
      (async () => {
        const url = await Storage.get(post.mediaS3Key);
        //console.log(url);
        setMediaUrl(url);
      })();
    }
  }, [post]);


  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleApproveClick = () => {
    setActionType('approve');
    setConfirmOpen(true);
  };

  const handleDeleteClick = () => {
    setActionType('delete');
    setConfirmOpen(true);
  };

  const handleApprovePostConfirm = async () => {

    handleApprovePost();
    removePost(post.id);
    setConfirmOpen(false); // Close the confirmation dialog
    setShowSnackbar(true); // Show the snackbar with a success message
  };

  const handleDeletePostConfirm = async () => {
    handleDeletePost()
    setConfirmOpen(false); // Close the confirmation dialog
    setShowSnackbar(true); // Show the snackbar with a success message
  };



  const handleDeletePost = async () => {
    try {
      await API.graphql(graphqlOperation(deleteCommunityPost, { input: { id: post.id } }));
      removePost(post.id);
      setShowSnackbar(true);
    } catch (error) {
      console.error('Error deleting post', error);
    }
    handleMenuClose();
  };

  const handleApprovePost = async () => {
    try {
      const updateResult = await API.graphql(graphqlOperation(updateCommunityPost, {
        input: {
          id: post.id, 
          needsModeration: false 
        }
      }));

      // Optionally, update the local state to reflect the change or refetch posts
      setPost(prevPost => ({ ...prevPost, needsModeration: false }));
      // If you have a mechanism to refetch or update the parent component's post list, do so here
    } catch (error) {
      console.error("Error approving post:", error);
    }
  };


  return (
    <div className="post">
      <div className="postWrapper">
        <div className="postTop">
          <div className="postTopLeft" onClick={() => navigateToUserProfile(post.User.id)}>
            {post?.User?.image ? (
              <S3Image S3Key={post?.User?.image} S3height={128} className="postProfileImg" />
            ) : (
              <img
                className="postProfileImg"
                src={post?.User?.image || 'assets/persons/default.png'}
                alt={`${post.User.firstName} ${post.User.lastName}`}
              />
            )}
            <span className="postUsername">
              {post?.User?.firstName ? `${post.User.firstName} ${post.User.lastName}` : post.User.name}
            </span>
            <span className="postDate">
              {new Date(post.dateTime).toLocaleString()}
            </span>
          </div>
          <div className="postTopRight">
            <div className="moderationButtons">
              <Button
                startIcon={<CheckCircleOutline />}
                onClick={handleApproveClick}
                variant="contained"
                color="success"
                style={{ marginRight: 8, height: '40px' }}
              >
                Approve
              </Button>
              <Button
                startIcon={<Delete />}
                onClick={handleDeleteClick}
                variant="contained"
                color="error"
                style={{ height: '40px' }}
              >
                Delete
              </Button>

            </div>
          </div>
        </div>
        <div className="postCenter">
          <span className="postText">{post.text}</span>
          <br />
          {post.communityPostGuidanceId && post.Guidance ? (
            <div className="guidanceWrapper" style={{ cursor: 'pointer' }} onClick={handleGuidanceClick}>
              <h3>{post.Guidance.title}</h3>
              <p>{post.Guidance.shortDesc}</p>
              {post.Guidance.S3ObjectKey && (
                <S3Image S3Key={post.Guidance.S3ObjectKey} S3height={350} className="guidanceImg" alt="" />
              )}
              <p className="guidanceLink">Click to read full article</p>
            </div>
          ) : (
            <>
              {(mediaUrl && post?.mediaS3Key?.endsWith('.mp4')) ? (
                <video controls className="postVideo" alt="" style={{ maxHeight: '450px' }}>
                  <source src={mediaUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <>
                  {post?.mediaS3Key && !post.mediaS3Key.endsWith('.mp4') ? (
                    <S3Image S3Key={post.mediaS3Key} S3height={350} className="postImg" alt="" />
                  ) : null}
                </>
              )}
            </>
          )}


        </div>
        <div className="postBottom">


        </div>



      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        message={actionType === 'delete' ? "Post deleted successfully" : "Post approved successfully"}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => setShowSnackbar(false)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />


      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="guidance-modal-title"
        fullWidth
        maxWidth="lg"
        style={{ width: '80%', height: '80%', margin: 'auto', overflow: 'hidden' }} // Add overflow hidden to ensure dialog respects boundaries
      >
        <DialogTitle id="guidance-modal-title" style={{ paddingBottom: 0, background: '#f0f0f0' }}>
          {post.Guidance?.title}
          <IconButton
            aria-label="close"
            onClick={() => setModalOpen(false)}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers style={{ overflow: 'auto', paddingTop: 0 }}>
          {post.Guidance?.S3ObjectKey && (
            <S3Image S3Key={post.Guidance.S3ObjectKey} S3height={350} className="guidanceImg" alt="" />
          )}
          <div style={{ marginTop: 20, marginBottom: 20, padding: '0 24px' }}>
            <p style={{ textAlign: 'justify' }}>{post.Guidance?.longDesc}</p>
          </div>
          <hr style={{ marginBottom: 20 }} />
          <div dangerouslySetInnerHTML={{ __html: guidanceContent?.content || '' }} style={{ padding: '0 24px' }} />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between', padding: '8px 24px', background: '#f0f0f0' }}>
          <Button onClick={() => console.log("Open in new window")}>Open in new window</Button>
          <Button onClick={() => console.log("Share with a friend")}>Share with a friend</Button>
          <Button onClick={() => console.log("Email for later")}>Email for later</Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {actionType === 'delete' ? 'Do you really want to delete this post?' : 'Do you want to approve this post?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => actionType === 'delete' ? handleDeletePostConfirm() : handleApprovePostConfirm()} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>



    </div>
  );
}
