import React, { useState, useRef, useMemo } from 'react';
import { Button, TextField, Typography, IconButton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; // Import for tick icon
import CloseIcon from '@mui/icons-material/Close';
import ReactQuill from 'react-quill';
import { v4 as uuidv4 } from 'uuid';
import 'react-quill/dist/quill.snow.css';
import "./AddArticle.css";
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { createGuidance, createGuidanceContent } from '../../graphql/mutations'; // Adjust the import as necessary

export default function AddArticle({ closeModal, onContentSubmit }) {
  const [stage, setStage] = useState(1);
  const [title, setTitle] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [imageUploadComplete, setImageUploadComplete] = useState(false); 
  const [content, setContent] = useState("");
  const [newGuidanceID, setNewGuidanceID] = useState("");
  const quillRef = useRef(null);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setThumbnail(file);
      const key = `thumbnails/${uuidv4()}-${file.name}`;
      await Storage.put(key, file, { contentType: file.type });
      setImageUploadComplete(true); // Set upload complete to true once the upload finishes
    }
  };

  const handleSubmitArticle = async (event) => {
    event.preventDefault();

    let bucketKey = "";

    const input = {
      title,
      lowerCaseTitle: title.toLowerCase(),
      shortDesc: shortDescription,
      lowerCaseShortDesc: shortDescription.toLowerCase(),
      guidanceGuidanceTypesId: "253be61e-98e0-4286-90f0-1cd4f49930dd",
      deleted: false,
      status: 'DRAFT'
    };

    if (thumbnail) {
      const key = `thumbnails/${uuidv4()}-${thumbnail.name}`;
      const response = await Storage.put(key, thumbnail, { contentType: thumbnail.type });
      bucketKey = response.key;
      input.S3ObjectKey = bucketKey;
    }

    try {
      const submitArticle = await API.graphql(graphqlOperation(createGuidance, { input }));
      // Assuming you want to capture the article ID for the next stage
      const newArticleId = submitArticle.data.createGuidance.id;
      setNewGuidanceID(newArticleId);
      //console.log("Article ID:", newArticleId); // For debugging
      

      // Proceed to the next stage or handle as needed
      setStage(2);
      // Optionally reset form fields here or when closing the modal
    } catch (error) {
      console.error("Error submitting article:", error);
    }
  };

  const insertToEditor = (url) => {
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();
    const index = range ? range.index : 0;
    quill.insertEmbed(index, 'image', url);
  };


  const resizeImage = async (file, maxWidth = 400, maxHeight = 400) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          resolve(new File([blob], file.name, {
            type: 'image/jpeg',
            lastModified: Date.now()
          }));
        }, 'image/jpeg', 0.95);
      };
      img.onerror = reject;
    });
  };

  const uploadToS3 = async (file) => {
    const key = 'contentImages/' + uuidv4() + '-' + file.name;

    try {
      const response = await Storage.put(key, file, {
        contentType: file.type,
        level: 'public',
      });

      const imageURL = `https://acecuraapp-storage180506-staging.s3.eu-west-2.amazonaws.com/public/${response.key}`;
      return imageURL;
    } catch (error) {
      console.error('Error uploading to S3', error);
    }
  }


  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const key = `articles/${uuidv4()}-${file.name}`;
      try {
        if (file) {
          const resizedFile = await resizeImage(file);
          const imageURL = await uploadToS3(resizedFile);
          insertToEditor(imageURL);
        }
      } catch (err) {
        console.error('Error uploading file:', err);
      }
    };
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image', 'video'],
        ['clean']
      ],
      handlers: {
        'image': selectLocalImage
      }
    },
  }), []);



  const handleSubmitContent = async (event) => {
    event.preventDefault();

    // Find and replace "<p><br></p>" with "<br>"
    //This helps with better spacing for content.
    var modifiedContent = content.replace(/<p><br><\/p>/g, "<br>");
    modifiedContent = modifiedContent.replace(/<\/p><br>/g, "<\/p>");
    modifiedContent = modifiedContent.replace(/<\/h2><br>/g, "<\/h2>");
    modifiedContent = modifiedContent.replace(/<h2><br><\/h2>/g, "");

    let cleanedContent = content.replace(/<br\s*\/?>/gi, " ").replace(/<\/li>/gi, " ");
    const textContent = cleanedContent.replace(/<\/?[^>]+(>|$)/g, "").replace(/\n/g, " ");

    // console.log("Text Content Without HTML Tags:", textContent);
    // console.log("Text Content WITH HTML Tags:", modifiedContent);

    try {
      // Create new guidance content
      const submitArticleContent = await API.graphql(
        graphqlOperation(createGuidanceContent, {
          input: {
            guidanceID: newGuidanceID, 
            title: "",
            content: modifiedContent,
            order: 1,
          },
        })
      );

      // Invoke callback with newGuidanceID
      onContentSubmit(newGuidanceID, title);

      // Close modal
      closeModal();


    } catch (error) {
      console.error(error);
    }

  };


  return (
    <div className="addArticleOverlay">
      <div className="addArticleModal">
        <IconButton aria-label="close" className="closeModal" onClick={closeModal}>
          <CloseIcon />
        </IconButton>
        {stage === 1 && (
          <>
            <Typography variant="h6" component="h2" style={{ textAlign: 'center', marginBottom: '20px' }}>
              Add New Article
            </Typography>
            <form onSubmit={handleSubmitArticle}>
              <TextField
                label="Title"
                variant="outlined"
                fullWidth
                margin="dense"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
              <TextField
                label="Short Description"
                variant="outlined"
                fullWidth
                margin="dense"
                value={shortDescription}
                onChange={(e) => setShortDescription(e.target.value)}
                required
              />
              <TextField
                label="Long Description"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                margin="dense"
                value={longDescription}
                onChange={(e) => setLongDescription(e.target.value)}
                required
              />
                        <Button
                variant="contained"
                component="label"
                color="primary"
                fullWidth
                style={{ margin: '10px 0' }}
                startIcon={imageUploadComplete ? <CheckCircleOutlineIcon /> : null}
              >
                {imageUploadComplete ? 'Image Uploaded' : 'Upload Image'}
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Button>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Next Step
              </Button>
            </form>
          </>
        )}
        {stage === 2 && (
          <>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Add Article Content
            </Typography>
            <ReactQuill
              ref={quillRef}
              value={content}
              onChange={setContent}
              modules={modules}
              theme="snow"
              style={{ marginBottom: '20px' }}
            />
            <Button variant="contained" color="primary" onClick={handleSubmitContent}>
              Submit
            </Button>
          </>
        )}

      </div>
    </div>
  );
}