import React, { useState, useEffect, useContext } from 'react';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { updateCommunityUserNotifications } from '../../graphql/mutations';
import { listCommunityUserNotifications } from '../../graphql/queries';
import { useLocation } from 'react-router-dom';
import "./topbar.css";
import { Search, Person, Chat, Notifications } from "@mui/icons-material";
import NotificationItem from "../NotificationItem/NotificationItem";
import { UserContext } from '../../App';
import S3Image from '../S3Image/S3Image';

export default function Topbar() {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [unseenCount, setUnseenCount] = useState(0);
    const location = useLocation();


    const userData = useContext(UserContext);
    //console.log(userData);

    const fetchNotifications = async () => {
        try {
            const notificationData = await API.graphql(graphqlOperation(listCommunityUserNotifications, {
                filter: {
                    communityUserNotificationsUserId: {
                        eq: userData?.id
                    }
                }
            }));
            const items = notificationData.data.listCommunityUserNotifications.items;
            setNotifications(items);

            // Calculate the number of unseen notifications
            const unseenNotifications = items.filter(notification => !notification.seen).length;
            setUnseenCount(unseenNotifications);

        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };

    useEffect(() => {
        if (userData?.id) {
            fetchNotifications();
        }
    }, [userData?.id, location]);


    const updateNotificationsAsSeen = async () => {
        const unseenNotifications = notifications.filter(notification => !notification.seen);

        const updatePromises = unseenNotifications.map(notification =>
            API.graphql(graphqlOperation(updateCommunityUserNotifications, {
                input: {
                    id: notification.id,
                    seen: true
                }
            }))
        );

        try {
            await Promise.all(updatePromises);

            // Update state to reflect changes
            setNotifications(notifications.map(notification => ({
                ...notification,
                seen: true
            })));

            // Reset unseen count
            setUnseenCount(0);
        } catch (error) {
            console.error('Error updating notifications:', error);
        }
    };

    const handleNotificationsClick = () => {
        setNotificationsOpen(!notificationsOpen);
        if (!notificationsOpen) {
            updateNotificationsAsSeen();
        }
    };

    const handleSignOut = async () => {
        try {
            await Auth.signOut();
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    return (
        <div className="topbarContainer">
            <div className="topbarLeft">
                <span className="logo">Acecura Community</span>
            </div>
            <div className="topbarCentre">
                <div className="searchbar">
                    <Search className="searchIcon" />
                    <input placeholder="Search for friends , Post or Articles" className="searchInput" />
                </div>
            </div>
            <div className="topbarRight">
                <div className="topbarLinks">

                </div>
                <div className="topbarIcons">
                    <div className="topbarIconItem">
                        <Person />
                        {/* <span className="topbarIconBadge">1</span> */}
                    </div>
                    <div className="topbarIconItem">
                        <Chat />
                        {/* <span className="topbarIconBadge">2</span> */}
                    </div>
                    <div className="topbarIconItem" onClick={handleNotificationsClick}>
                        <Notifications />
                        {unseenCount > 0 && <span className="topbarIconBadge">{unseenCount}</span>}
                    </div>
                    {notificationsOpen && (
                        <div className="notificationsDropdown">
                            {notifications.map((notification, index) => (
                                <NotificationItem
                                    key={index}
                                    notification={notification}
                                    refreshNotifications={fetchNotifications} 
                                />))}
                        </div>
                    )}
                </div>
                <div className="topbarEmail">{userData?.email}</div>
                {userData?.image ? (
                    <S3Image S3Key={userData?.image} S3height={32} className="topbarImg" onClick={() => setDropdownOpen((prev) => !prev)} />
                ) : (
                    <img
                        src={userData?.image || 'assets/persons/default.png'}
                        alt=""
                        className="topbarImg"
                        onClick={() => setDropdownOpen((prev) => !prev)}
                    />)}

                {dropdownOpen && (
                    <div className="dropdownMenu">
                        <button onClick={handleSignOut}>Sign Out</button>
                    </div>
                )}
            </div>
        </div>
    )
}
