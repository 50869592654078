import React, { createContext, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Amplify, Auth, API, graphqlOperation } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { Hub } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';
import Home from "./pages/home/Home.jsx";
import Profile from "./pages/profile/Profile.jsx";
import AdminGroups from "./pages/adminGroups/AdminGroups";
import ManageGroup from "./pages/manageGroup/manageGroup";
import Group from "./pages/group/Group";
import Layout from "./components/layout/Layout";
import Moderation from "./pages/moderation/Moderation";
import { getUser } from './graphql/queries';
import { listCommunityUserGroupRoles } from './graphql/queries'; 
import { createCommunityUserGroupRoles } from './graphql/mutations'; 


Amplify.configure(awsconfig);

export const UserContext = createContext(null);

function App() {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const listener = Hub.listen('auth', ({ payload: { event, data } }) => {
      if (event === 'signIn' && data) {
        setUserData({ email: data.signInUserSession.idToken.payload.email, id: data.signInUserSession.idToken.payload.sub });
      }
      if (event === 'signOut') {
        setUserData(null);
      }
    });

    return () => {
      Hub.remove('auth', listener);
    };
  }, []);

  useEffect(() => {
    const fetchUserGroups = async () => {
      try {
        if (userData && userData.id) {
          const userGroupRoles = await API.graphql(graphqlOperation(listCommunityUserGroupRoles, {
            filter: {communityUserGroupRolesUserId: {eq: userData.id}}
          }));
    
          let hasGroup = false;
          if (userGroupRoles.data.listCommunityUserGroupRoles) {
            const groups = userGroupRoles.data.listCommunityUserGroupRoles.items.map(item => {
              if (item.CommunityGroup.id === "ba70f770-cd78-4ba3-93e2-36f39a598f82") {
                hasGroup = true;
              }
              return {
                groupId: item.CommunityGroup.id,
                name: item.CommunityGroup.name,
                role: item.userGroupRole,
                headerS3Key: item.CommunityGroup.headerS3Key
              };
            });
    
            setUserData(prev => ({ ...prev, groups }));
          }
    
          if (!hasGroup) {
            await API.graphql(graphqlOperation(createCommunityUserGroupRoles, {
              input: {
                communityUserGroupRolesUserId: userData.id,
                communityUserGroupRolesCommunityGroupId: "ba70f770-cd78-4ba3-93e2-36f39a598f82",
                userGroupRole: "USER"
              }
            }));
            // Optionally, refetch the groups to update UI or state
          }
        }
      } catch (error) {
        console.error("Error fetching user groups or adding to group: ", error);
      }
    };
    

    if (userData && !userData.groups) {
      fetchUserGroups();
    }
  }, [userData]);

  return (
    <Authenticator>
      {({ signOut, user }) => {
        const fetchData = async () => {
          try {
            if (user && user.attributes && !userData) {
              const userDataQuery = await API.graphql(
                graphqlOperation(getUser, {
                  id: user.attributes.sub,
                })
              );

              if (userDataQuery.data.getUser) {
                setUserData(
                  {
                    email: user.attributes.email,
                    id: user.attributes.sub,
                    firstName: userDataQuery.data.getUser.firstName,
                    lastName: userDataQuery.data.getUser.lastName,
                    image: userDataQuery.data.getUser.image,
                    userType: userDataQuery.data.getUser.userType
                  });
              }
            }
          } catch (error) {
            console.error("Error fetching user data: ", error);
          }
        };

        fetchData();

        return (
          <UserContext.Provider value={userData}>
             <Router>
            <Layout userData={userData}>
              <Routes>
                <Route path="/"  />
                <Route path="/admin-groups" element={<AdminGroups />} />
                <Route path="/moderation" element={<Moderation />} />
                <Route path="/manage-group/:groupId" element={<ManageGroup />} />
                <Route path="/group/:groupId" element={<Group />} />
                <Route path="/profile/:userId" element={<Profile />} />
              </Routes>
            </Layout>
            </Router>
          </UserContext.Provider>
        );
      }}
    </Authenticator>
  );
}

export default App;
