import React from 'react'
import './rightbar.css'
import { Users } from "../../dummyData"
import Online from '../online/Online'

export default function Rightbar({ profile }) {

    const HomeRightbar = () => {
        return (
            <div className='rightBarContainer'>
                <h4 className="rightbarTitle">Coming Soon: Acecura App</h4>
            <div className="comingSoonContainer">

                <img className='rightbarAd' src="assets/persons/Tasks-app.png" alt="" />
            </div>
            <h4 className="rightbarTitle">Online:</h4>
            <ul className="rightbarFriendList">
                {Users.map(u => (
                    <Online key={u.id} user={u} />
                ))}
            </ul>
        </div>
        
        )
    }

    const ProfileRightbar = () => {
        return (
            <>
                <h4 className='rightbarTitle'>User information</h4>
                <div className="rightbarInfo">
                    <div className="rightbarInfoItem">
                        <span className="rightbarInfoKey">Location:</span>
                        <span className="rightbarInfoValue">New York</span>
                    </div>
                    <div className="rightbarInfoItem">
                        <span className="rightbarInfoKey">From:</span>
                        <span className="rightbarInfoValue">Kerala</span>
                    </div>
                    <div className="rightbarInfoItem">
                        <span className="rightbarInfoKey">Relationship:</span>
                        <span className="rightbarInfoValue">Single</span>
                    </div>
                </div>
                <h4 className='rightbarTitle'>User friends</h4>
                <div className="rightbarFollowings">
                    <div className="rightbarFollowing">
                        <img src="../assets/persons/5.jpg" alt="" className="rightbarFollowingImg" />
                        <span className="rightbarFollowingname">Emily Carter</span>
                    </div>
                    <div className="rightbarFollowing">
                        <img src="../assets/persons/6.jpg" alt="" className="rightbarFollowingImg" />
                        
                        <span className="rightbarFollowingname">John Carter</span>
                    </div>
                    <div className="rightbarFollowing">
                        <img src="../assets/persons/7.jpg" alt="" className="rightbarFollowingImg" />
                        <span className="rightbarFollowingname">Sasha banks</span>
                    </div>
                </div>
            </>
        )
    }
    return (
        <div className='rightbar'>
            <div className="rightbarWrapper">
                {profile ? <ProfileRightbar /> : <HomeRightbar/> }
            </div>
        </div>
    )
}