/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createMyChats = /* GraphQL */ `mutation CreateMyChats(
  $input: CreateMyChatsInput!
  $condition: ModelMyChatsConditionInput
) {
  createMyChats(input: $input, condition: $condition) {
    id
    unreadMessages
    lastMessageDateTime
    lastMessage
    chatName
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    ChatRoom {
      id
      name
      image
      Messages {
        nextToken
        __typename
      }
      LastMessage {
        id
        createdAt
        text
        chatroomID
        userID
        mediaS3URL
        updatedAt
        messageUserId
        __typename
      }
      users {
        nextToken
        __typename
      }
      isChatBot
      createdAt
      updatedAt
      chatRoomLastMessageId
      __typename
    }
    chatImageS3URL
    createdAt
    updatedAt
    myChatsUserId
    myChatsChatRoomId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMyChatsMutationVariables,
  APITypes.CreateMyChatsMutation
>;
export const updateMyChats = /* GraphQL */ `mutation UpdateMyChats(
  $input: UpdateMyChatsInput!
  $condition: ModelMyChatsConditionInput
) {
  updateMyChats(input: $input, condition: $condition) {
    id
    unreadMessages
    lastMessageDateTime
    lastMessage
    chatName
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    ChatRoom {
      id
      name
      image
      Messages {
        nextToken
        __typename
      }
      LastMessage {
        id
        createdAt
        text
        chatroomID
        userID
        mediaS3URL
        updatedAt
        messageUserId
        __typename
      }
      users {
        nextToken
        __typename
      }
      isChatBot
      createdAt
      updatedAt
      chatRoomLastMessageId
      __typename
    }
    chatImageS3URL
    createdAt
    updatedAt
    myChatsUserId
    myChatsChatRoomId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMyChatsMutationVariables,
  APITypes.UpdateMyChatsMutation
>;
export const deleteMyChats = /* GraphQL */ `mutation DeleteMyChats(
  $input: DeleteMyChatsInput!
  $condition: ModelMyChatsConditionInput
) {
  deleteMyChats(input: $input, condition: $condition) {
    id
    unreadMessages
    lastMessageDateTime
    lastMessage
    chatName
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    ChatRoom {
      id
      name
      image
      Messages {
        nextToken
        __typename
      }
      LastMessage {
        id
        createdAt
        text
        chatroomID
        userID
        mediaS3URL
        updatedAt
        messageUserId
        __typename
      }
      users {
        nextToken
        __typename
      }
      isChatBot
      createdAt
      updatedAt
      chatRoomLastMessageId
      __typename
    }
    chatImageS3URL
    createdAt
    updatedAt
    myChatsUserId
    myChatsChatRoomId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMyChatsMutationVariables,
  APITypes.DeleteMyChatsMutation
>;
export const createDebugInfo = /* GraphQL */ `mutation CreateDebugInfo(
  $input: CreateDebugInfoInput!
  $condition: ModelDebugInfoConditionInput
) {
  createDebugInfo(input: $input, condition: $condition) {
    id
    user
    Logging
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDebugInfoMutationVariables,
  APITypes.CreateDebugInfoMutation
>;
export const updateDebugInfo = /* GraphQL */ `mutation UpdateDebugInfo(
  $input: UpdateDebugInfoInput!
  $condition: ModelDebugInfoConditionInput
) {
  updateDebugInfo(input: $input, condition: $condition) {
    id
    user
    Logging
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDebugInfoMutationVariables,
  APITypes.UpdateDebugInfoMutation
>;
export const deleteDebugInfo = /* GraphQL */ `mutation DeleteDebugInfo(
  $input: DeleteDebugInfoInput!
  $condition: ModelDebugInfoConditionInput
) {
  deleteDebugInfo(input: $input, condition: $condition) {
    id
    user
    Logging
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDebugInfoMutationVariables,
  APITypes.DeleteDebugInfoMutation
>;
export const createMyFriends = /* GraphQL */ `mutation CreateMyFriends(
  $input: CreateMyFriendsInput!
  $condition: ModelMyFriendsConditionInput
) {
  createMyFriends(input: $input, condition: $condition) {
    id
    status
    Users {
      items {
        id
        myFriendsID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMyFriendsMutationVariables,
  APITypes.CreateMyFriendsMutation
>;
export const updateMyFriends = /* GraphQL */ `mutation UpdateMyFriends(
  $input: UpdateMyFriendsInput!
  $condition: ModelMyFriendsConditionInput
) {
  updateMyFriends(input: $input, condition: $condition) {
    id
    status
    Users {
      items {
        id
        myFriendsID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMyFriendsMutationVariables,
  APITypes.UpdateMyFriendsMutation
>;
export const deleteMyFriends = /* GraphQL */ `mutation DeleteMyFriends(
  $input: DeleteMyFriendsInput!
  $condition: ModelMyFriendsConditionInput
) {
  deleteMyFriends(input: $input, condition: $condition) {
    id
    status
    Users {
      items {
        id
        myFriendsID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMyFriendsMutationVariables,
  APITypes.DeleteMyFriendsMutation
>;
export const createExpoNotifications = /* GraphQL */ `mutation CreateExpoNotifications(
  $input: CreateExpoNotificationsInput!
  $condition: ModelExpoNotificationsConditionInput
) {
  createExpoNotifications(input: $input, condition: $condition) {
    id
    title
    description
    seen
    data
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    CommunityPost {
      id
      text
      mediaS3Key
      dateTime
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      CommunityComments {
        nextToken
        __typename
      }
      CommunityLikes {
        nextToken
        __typename
      }
      CommunityPostPoll {
        nextToken
        __typename
      }
      communitygroupID
      Guidance {
        id
        title
        shortDesc
        longDesc
        thumbURL
        date
        time
        estTime
        deleted
        location
        author
        S3ObjectKey
        status
        active
        lowerCaseTitle
        lowerCaseShortDesc
        scheduleDateTime
        createdAt
        updatedAt
        guidanceGuidanceTypesId
        __typename
      }
      needsModeration
      moderationLabel
      moderationScore
      createdAt
      updatedAt
      communityPostUserId
      communityPostGuidanceId
      __typename
    }
    CommunityComment {
      id
      text
      likes
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      communitypostID
      createdAt
      updatedAt
      communityCommentUserId
      __typename
    }
    CommunityLikes {
      id
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      communitypostID
      createdAt
      updatedAt
      communityLikesUserId
      __typename
    }
    Guidance {
      id
      title
      shortDesc
      longDesc
      thumbURL
      date
      time
      estTime
      deleted
      GuidanceTypes {
        id
        name
        description
        colour
        deleted
        createdAt
        updatedAt
        __typename
      }
      location
      author
      S3ObjectKey
      GuidanceContents {
        nextToken
        __typename
      }
      status
      active
      lowerCaseTitle
      lowerCaseShortDesc
      scheduleDateTime
      createdAt
      updatedAt
      guidanceGuidanceTypesId
      __typename
    }
    NotificationType
    createdAt
    updatedAt
    expoNotificationsUserId
    expoNotificationsCommunityPostId
    expoNotificationsCommunityCommentId
    expoNotificationsCommunityLikesId
    expoNotificationsGuidanceId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateExpoNotificationsMutationVariables,
  APITypes.CreateExpoNotificationsMutation
>;
export const updateExpoNotifications = /* GraphQL */ `mutation UpdateExpoNotifications(
  $input: UpdateExpoNotificationsInput!
  $condition: ModelExpoNotificationsConditionInput
) {
  updateExpoNotifications(input: $input, condition: $condition) {
    id
    title
    description
    seen
    data
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    CommunityPost {
      id
      text
      mediaS3Key
      dateTime
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      CommunityComments {
        nextToken
        __typename
      }
      CommunityLikes {
        nextToken
        __typename
      }
      CommunityPostPoll {
        nextToken
        __typename
      }
      communitygroupID
      Guidance {
        id
        title
        shortDesc
        longDesc
        thumbURL
        date
        time
        estTime
        deleted
        location
        author
        S3ObjectKey
        status
        active
        lowerCaseTitle
        lowerCaseShortDesc
        scheduleDateTime
        createdAt
        updatedAt
        guidanceGuidanceTypesId
        __typename
      }
      needsModeration
      moderationLabel
      moderationScore
      createdAt
      updatedAt
      communityPostUserId
      communityPostGuidanceId
      __typename
    }
    CommunityComment {
      id
      text
      likes
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      communitypostID
      createdAt
      updatedAt
      communityCommentUserId
      __typename
    }
    CommunityLikes {
      id
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      communitypostID
      createdAt
      updatedAt
      communityLikesUserId
      __typename
    }
    Guidance {
      id
      title
      shortDesc
      longDesc
      thumbURL
      date
      time
      estTime
      deleted
      GuidanceTypes {
        id
        name
        description
        colour
        deleted
        createdAt
        updatedAt
        __typename
      }
      location
      author
      S3ObjectKey
      GuidanceContents {
        nextToken
        __typename
      }
      status
      active
      lowerCaseTitle
      lowerCaseShortDesc
      scheduleDateTime
      createdAt
      updatedAt
      guidanceGuidanceTypesId
      __typename
    }
    NotificationType
    createdAt
    updatedAt
    expoNotificationsUserId
    expoNotificationsCommunityPostId
    expoNotificationsCommunityCommentId
    expoNotificationsCommunityLikesId
    expoNotificationsGuidanceId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateExpoNotificationsMutationVariables,
  APITypes.UpdateExpoNotificationsMutation
>;
export const deleteExpoNotifications = /* GraphQL */ `mutation DeleteExpoNotifications(
  $input: DeleteExpoNotificationsInput!
  $condition: ModelExpoNotificationsConditionInput
) {
  deleteExpoNotifications(input: $input, condition: $condition) {
    id
    title
    description
    seen
    data
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    CommunityPost {
      id
      text
      mediaS3Key
      dateTime
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      CommunityComments {
        nextToken
        __typename
      }
      CommunityLikes {
        nextToken
        __typename
      }
      CommunityPostPoll {
        nextToken
        __typename
      }
      communitygroupID
      Guidance {
        id
        title
        shortDesc
        longDesc
        thumbURL
        date
        time
        estTime
        deleted
        location
        author
        S3ObjectKey
        status
        active
        lowerCaseTitle
        lowerCaseShortDesc
        scheduleDateTime
        createdAt
        updatedAt
        guidanceGuidanceTypesId
        __typename
      }
      needsModeration
      moderationLabel
      moderationScore
      createdAt
      updatedAt
      communityPostUserId
      communityPostGuidanceId
      __typename
    }
    CommunityComment {
      id
      text
      likes
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      communitypostID
      createdAt
      updatedAt
      communityCommentUserId
      __typename
    }
    CommunityLikes {
      id
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      communitypostID
      createdAt
      updatedAt
      communityLikesUserId
      __typename
    }
    Guidance {
      id
      title
      shortDesc
      longDesc
      thumbURL
      date
      time
      estTime
      deleted
      GuidanceTypes {
        id
        name
        description
        colour
        deleted
        createdAt
        updatedAt
        __typename
      }
      location
      author
      S3ObjectKey
      GuidanceContents {
        nextToken
        __typename
      }
      status
      active
      lowerCaseTitle
      lowerCaseShortDesc
      scheduleDateTime
      createdAt
      updatedAt
      guidanceGuidanceTypesId
      __typename
    }
    NotificationType
    createdAt
    updatedAt
    expoNotificationsUserId
    expoNotificationsCommunityPostId
    expoNotificationsCommunityCommentId
    expoNotificationsCommunityLikesId
    expoNotificationsGuidanceId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteExpoNotificationsMutationVariables,
  APITypes.DeleteExpoNotificationsMutation
>;
export const createSystemInfo = /* GraphQL */ `mutation CreateSystemInfo(
  $input: CreateSystemInfoInput!
  $condition: ModelSystemInfoConditionInput
) {
  createSystemInfo(input: $input, condition: $condition) {
    id
    name
    value
    options
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSystemInfoMutationVariables,
  APITypes.CreateSystemInfoMutation
>;
export const updateSystemInfo = /* GraphQL */ `mutation UpdateSystemInfo(
  $input: UpdateSystemInfoInput!
  $condition: ModelSystemInfoConditionInput
) {
  updateSystemInfo(input: $input, condition: $condition) {
    id
    name
    value
    options
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSystemInfoMutationVariables,
  APITypes.UpdateSystemInfoMutation
>;
export const deleteSystemInfo = /* GraphQL */ `mutation DeleteSystemInfo(
  $input: DeleteSystemInfoInput!
  $condition: ModelSystemInfoConditionInput
) {
  deleteSystemInfo(input: $input, condition: $condition) {
    id
    name
    value
    options
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSystemInfoMutationVariables,
  APITypes.DeleteSystemInfoMutation
>;
export const createCareHomes = /* GraphQL */ `mutation CreateCareHomes(
  $input: CreateCareHomesInput!
  $condition: ModelCareHomesConditionInput
) {
  createCareHomes(input: $input, condition: $condition) {
    id
    LocationID
    Name
    CareHome
    Type
    Category
    Address1
    Address2
    City
    PostCode
    LocalAuthority
    Region
    ServiceGroup
    CQCURL
    ProviderID
    ProviderName
    RatingCaring
    RatingEffective
    RatingResponsive
    RatingSafe
    RatingWellLed
    RatingOverall
    NHSRegion
    ReportDate
    iRatingCaring
    iRatingEffective
    iRatingResponsive
    iRatingSafe
    iRatingWellLed
    iRatingOverall
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCareHomesMutationVariables,
  APITypes.CreateCareHomesMutation
>;
export const updateCareHomes = /* GraphQL */ `mutation UpdateCareHomes(
  $input: UpdateCareHomesInput!
  $condition: ModelCareHomesConditionInput
) {
  updateCareHomes(input: $input, condition: $condition) {
    id
    LocationID
    Name
    CareHome
    Type
    Category
    Address1
    Address2
    City
    PostCode
    LocalAuthority
    Region
    ServiceGroup
    CQCURL
    ProviderID
    ProviderName
    RatingCaring
    RatingEffective
    RatingResponsive
    RatingSafe
    RatingWellLed
    RatingOverall
    NHSRegion
    ReportDate
    iRatingCaring
    iRatingEffective
    iRatingResponsive
    iRatingSafe
    iRatingWellLed
    iRatingOverall
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCareHomesMutationVariables,
  APITypes.UpdateCareHomesMutation
>;
export const deleteCareHomes = /* GraphQL */ `mutation DeleteCareHomes(
  $input: DeleteCareHomesInput!
  $condition: ModelCareHomesConditionInput
) {
  deleteCareHomes(input: $input, condition: $condition) {
    id
    LocationID
    Name
    CareHome
    Type
    Category
    Address1
    Address2
    City
    PostCode
    LocalAuthority
    Region
    ServiceGroup
    CQCURL
    ProviderID
    ProviderName
    RatingCaring
    RatingEffective
    RatingResponsive
    RatingSafe
    RatingWellLed
    RatingOverall
    NHSRegion
    ReportDate
    iRatingCaring
    iRatingEffective
    iRatingResponsive
    iRatingSafe
    iRatingWellLed
    iRatingOverall
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCareHomesMutationVariables,
  APITypes.DeleteCareHomesMutation
>;
export const createUserExtendedInfo = /* GraphQL */ `mutation CreateUserExtendedInfo(
  $input: CreateUserExtendedInfoInput!
  $condition: ModelUserExtendedInfoConditionInput
) {
  createUserExtendedInfo(input: $input, condition: $condition) {
    id
    field
    value
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserExtendedInfoMutationVariables,
  APITypes.CreateUserExtendedInfoMutation
>;
export const updateUserExtendedInfo = /* GraphQL */ `mutation UpdateUserExtendedInfo(
  $input: UpdateUserExtendedInfoInput!
  $condition: ModelUserExtendedInfoConditionInput
) {
  updateUserExtendedInfo(input: $input, condition: $condition) {
    id
    field
    value
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserExtendedInfoMutationVariables,
  APITypes.UpdateUserExtendedInfoMutation
>;
export const deleteUserExtendedInfo = /* GraphQL */ `mutation DeleteUserExtendedInfo(
  $input: DeleteUserExtendedInfoInput!
  $condition: ModelUserExtendedInfoConditionInput
) {
  deleteUserExtendedInfo(input: $input, condition: $condition) {
    id
    field
    value
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserExtendedInfoMutationVariables,
  APITypes.DeleteUserExtendedInfoMutation
>;
export const createCommunityUserNotifications = /* GraphQL */ `mutation CreateCommunityUserNotifications(
  $input: CreateCommunityUserNotificationsInput!
  $condition: ModelCommunityUserNotificationsConditionInput
) {
  createCommunityUserNotifications(input: $input, condition: $condition) {
    id
    NotificationType
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    CommunityLikes {
      id
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      communitypostID
      createdAt
      updatedAt
      communityLikesUserId
      __typename
    }
    CommunityComment {
      id
      text
      likes
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      communitypostID
      createdAt
      updatedAt
      communityCommentUserId
      __typename
    }
    seen
    CommunityGroup {
      id
      CommunityPosts {
        nextToken
        __typename
      }
      name
      description
      imageS3Key
      headerS3Key
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    communityUserNotificationsUserId
    communityUserNotificationsCommunityLikesId
    communityUserNotificationsCommunityCommentId
    communityUserNotificationsCommunityGroupId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommunityUserNotificationsMutationVariables,
  APITypes.CreateCommunityUserNotificationsMutation
>;
export const updateCommunityUserNotifications = /* GraphQL */ `mutation UpdateCommunityUserNotifications(
  $input: UpdateCommunityUserNotificationsInput!
  $condition: ModelCommunityUserNotificationsConditionInput
) {
  updateCommunityUserNotifications(input: $input, condition: $condition) {
    id
    NotificationType
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    CommunityLikes {
      id
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      communitypostID
      createdAt
      updatedAt
      communityLikesUserId
      __typename
    }
    CommunityComment {
      id
      text
      likes
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      communitypostID
      createdAt
      updatedAt
      communityCommentUserId
      __typename
    }
    seen
    CommunityGroup {
      id
      CommunityPosts {
        nextToken
        __typename
      }
      name
      description
      imageS3Key
      headerS3Key
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    communityUserNotificationsUserId
    communityUserNotificationsCommunityLikesId
    communityUserNotificationsCommunityCommentId
    communityUserNotificationsCommunityGroupId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommunityUserNotificationsMutationVariables,
  APITypes.UpdateCommunityUserNotificationsMutation
>;
export const deleteCommunityUserNotifications = /* GraphQL */ `mutation DeleteCommunityUserNotifications(
  $input: DeleteCommunityUserNotificationsInput!
  $condition: ModelCommunityUserNotificationsConditionInput
) {
  deleteCommunityUserNotifications(input: $input, condition: $condition) {
    id
    NotificationType
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    CommunityLikes {
      id
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      communitypostID
      createdAt
      updatedAt
      communityLikesUserId
      __typename
    }
    CommunityComment {
      id
      text
      likes
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      communitypostID
      createdAt
      updatedAt
      communityCommentUserId
      __typename
    }
    seen
    CommunityGroup {
      id
      CommunityPosts {
        nextToken
        __typename
      }
      name
      description
      imageS3Key
      headerS3Key
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    communityUserNotificationsUserId
    communityUserNotificationsCommunityLikesId
    communityUserNotificationsCommunityCommentId
    communityUserNotificationsCommunityGroupId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommunityUserNotificationsMutationVariables,
  APITypes.DeleteCommunityUserNotificationsMutation
>;
export const createQuestionGroupTypes = /* GraphQL */ `mutation CreateQuestionGroupTypes(
  $input: CreateQuestionGroupTypesInput!
  $condition: ModelQuestionGroupTypesConditionInput
) {
  createQuestionGroupTypes(input: $input, condition: $condition) {
    id
    name
    iconS3URL
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuestionGroupTypesMutationVariables,
  APITypes.CreateQuestionGroupTypesMutation
>;
export const updateQuestionGroupTypes = /* GraphQL */ `mutation UpdateQuestionGroupTypes(
  $input: UpdateQuestionGroupTypesInput!
  $condition: ModelQuestionGroupTypesConditionInput
) {
  updateQuestionGroupTypes(input: $input, condition: $condition) {
    id
    name
    iconS3URL
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuestionGroupTypesMutationVariables,
  APITypes.UpdateQuestionGroupTypesMutation
>;
export const deleteQuestionGroupTypes = /* GraphQL */ `mutation DeleteQuestionGroupTypes(
  $input: DeleteQuestionGroupTypesInput!
  $condition: ModelQuestionGroupTypesConditionInput
) {
  deleteQuestionGroupTypes(input: $input, condition: $condition) {
    id
    name
    iconS3URL
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuestionGroupTypesMutationVariables,
  APITypes.DeleteQuestionGroupTypesMutation
>;
export const createCommunityUserGroupRoles = /* GraphQL */ `mutation CreateCommunityUserGroupRoles(
  $input: CreateCommunityUserGroupRolesInput!
  $condition: ModelCommunityUserGroupRolesConditionInput
) {
  createCommunityUserGroupRoles(input: $input, condition: $condition) {
    id
    userGroupRole
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    CommunityGroup {
      id
      CommunityPosts {
        nextToken
        __typename
      }
      name
      description
      imageS3Key
      headerS3Key
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    communityUserGroupRolesUserId
    communityUserGroupRolesCommunityGroupId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommunityUserGroupRolesMutationVariables,
  APITypes.CreateCommunityUserGroupRolesMutation
>;
export const updateCommunityUserGroupRoles = /* GraphQL */ `mutation UpdateCommunityUserGroupRoles(
  $input: UpdateCommunityUserGroupRolesInput!
  $condition: ModelCommunityUserGroupRolesConditionInput
) {
  updateCommunityUserGroupRoles(input: $input, condition: $condition) {
    id
    userGroupRole
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    CommunityGroup {
      id
      CommunityPosts {
        nextToken
        __typename
      }
      name
      description
      imageS3Key
      headerS3Key
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    communityUserGroupRolesUserId
    communityUserGroupRolesCommunityGroupId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommunityUserGroupRolesMutationVariables,
  APITypes.UpdateCommunityUserGroupRolesMutation
>;
export const deleteCommunityUserGroupRoles = /* GraphQL */ `mutation DeleteCommunityUserGroupRoles(
  $input: DeleteCommunityUserGroupRolesInput!
  $condition: ModelCommunityUserGroupRolesConditionInput
) {
  deleteCommunityUserGroupRoles(input: $input, condition: $condition) {
    id
    userGroupRole
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    CommunityGroup {
      id
      CommunityPosts {
        nextToken
        __typename
      }
      name
      description
      imageS3Key
      headerS3Key
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    communityUserGroupRolesUserId
    communityUserGroupRolesCommunityGroupId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommunityUserGroupRolesMutationVariables,
  APITypes.DeleteCommunityUserGroupRolesMutation
>;
export const createCommunityGroup = /* GraphQL */ `mutation CreateCommunityGroup(
  $input: CreateCommunityGroupInput!
  $condition: ModelCommunityGroupConditionInput
) {
  createCommunityGroup(input: $input, condition: $condition) {
    id
    CommunityPosts {
      items {
        id
        text
        mediaS3Key
        dateTime
        communitygroupID
        needsModeration
        moderationLabel
        moderationScore
        createdAt
        updatedAt
        communityPostUserId
        communityPostGuidanceId
        __typename
      }
      nextToken
      __typename
    }
    name
    description
    imageS3Key
    headerS3Key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommunityGroupMutationVariables,
  APITypes.CreateCommunityGroupMutation
>;
export const updateCommunityGroup = /* GraphQL */ `mutation UpdateCommunityGroup(
  $input: UpdateCommunityGroupInput!
  $condition: ModelCommunityGroupConditionInput
) {
  updateCommunityGroup(input: $input, condition: $condition) {
    id
    CommunityPosts {
      items {
        id
        text
        mediaS3Key
        dateTime
        communitygroupID
        needsModeration
        moderationLabel
        moderationScore
        createdAt
        updatedAt
        communityPostUserId
        communityPostGuidanceId
        __typename
      }
      nextToken
      __typename
    }
    name
    description
    imageS3Key
    headerS3Key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommunityGroupMutationVariables,
  APITypes.UpdateCommunityGroupMutation
>;
export const deleteCommunityGroup = /* GraphQL */ `mutation DeleteCommunityGroup(
  $input: DeleteCommunityGroupInput!
  $condition: ModelCommunityGroupConditionInput
) {
  deleteCommunityGroup(input: $input, condition: $condition) {
    id
    CommunityPosts {
      items {
        id
        text
        mediaS3Key
        dateTime
        communitygroupID
        needsModeration
        moderationLabel
        moderationScore
        createdAt
        updatedAt
        communityPostUserId
        communityPostGuidanceId
        __typename
      }
      nextToken
      __typename
    }
    name
    description
    imageS3Key
    headerS3Key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommunityGroupMutationVariables,
  APITypes.DeleteCommunityGroupMutation
>;
export const createCommunityPostPollVotes = /* GraphQL */ `mutation CreateCommunityPostPollVotes(
  $input: CreateCommunityPostPollVotesInput!
  $condition: ModelCommunityPostPollVotesConditionInput
) {
  createCommunityPostPollVotes(input: $input, condition: $condition) {
    id
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    communitypostpollID
    createdAt
    updatedAt
    communityPostPollVotesUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommunityPostPollVotesMutationVariables,
  APITypes.CreateCommunityPostPollVotesMutation
>;
export const updateCommunityPostPollVotes = /* GraphQL */ `mutation UpdateCommunityPostPollVotes(
  $input: UpdateCommunityPostPollVotesInput!
  $condition: ModelCommunityPostPollVotesConditionInput
) {
  updateCommunityPostPollVotes(input: $input, condition: $condition) {
    id
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    communitypostpollID
    createdAt
    updatedAt
    communityPostPollVotesUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommunityPostPollVotesMutationVariables,
  APITypes.UpdateCommunityPostPollVotesMutation
>;
export const deleteCommunityPostPollVotes = /* GraphQL */ `mutation DeleteCommunityPostPollVotes(
  $input: DeleteCommunityPostPollVotesInput!
  $condition: ModelCommunityPostPollVotesConditionInput
) {
  deleteCommunityPostPollVotes(input: $input, condition: $condition) {
    id
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    communitypostpollID
    createdAt
    updatedAt
    communityPostPollVotesUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommunityPostPollVotesMutationVariables,
  APITypes.DeleteCommunityPostPollVotesMutation
>;
export const createCommunityPostPoll = /* GraphQL */ `mutation CreateCommunityPostPoll(
  $input: CreateCommunityPostPollInput!
  $condition: ModelCommunityPostPollConditionInput
) {
  createCommunityPostPoll(input: $input, condition: $condition) {
    id
    text
    CommunityPostPollVotes {
      items {
        id
        communitypostpollID
        createdAt
        updatedAt
        communityPostPollVotesUserId
        __typename
      }
      nextToken
      __typename
    }
    communitypostID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommunityPostPollMutationVariables,
  APITypes.CreateCommunityPostPollMutation
>;
export const updateCommunityPostPoll = /* GraphQL */ `mutation UpdateCommunityPostPoll(
  $input: UpdateCommunityPostPollInput!
  $condition: ModelCommunityPostPollConditionInput
) {
  updateCommunityPostPoll(input: $input, condition: $condition) {
    id
    text
    CommunityPostPollVotes {
      items {
        id
        communitypostpollID
        createdAt
        updatedAt
        communityPostPollVotesUserId
        __typename
      }
      nextToken
      __typename
    }
    communitypostID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommunityPostPollMutationVariables,
  APITypes.UpdateCommunityPostPollMutation
>;
export const deleteCommunityPostPoll = /* GraphQL */ `mutation DeleteCommunityPostPoll(
  $input: DeleteCommunityPostPollInput!
  $condition: ModelCommunityPostPollConditionInput
) {
  deleteCommunityPostPoll(input: $input, condition: $condition) {
    id
    text
    CommunityPostPollVotes {
      items {
        id
        communitypostpollID
        createdAt
        updatedAt
        communityPostPollVotesUserId
        __typename
      }
      nextToken
      __typename
    }
    communitypostID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommunityPostPollMutationVariables,
  APITypes.DeleteCommunityPostPollMutation
>;
export const createCommunityLikes = /* GraphQL */ `mutation CreateCommunityLikes(
  $input: CreateCommunityLikesInput!
  $condition: ModelCommunityLikesConditionInput
) {
  createCommunityLikes(input: $input, condition: $condition) {
    id
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    communitypostID
    createdAt
    updatedAt
    communityLikesUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommunityLikesMutationVariables,
  APITypes.CreateCommunityLikesMutation
>;
export const updateCommunityLikes = /* GraphQL */ `mutation UpdateCommunityLikes(
  $input: UpdateCommunityLikesInput!
  $condition: ModelCommunityLikesConditionInput
) {
  updateCommunityLikes(input: $input, condition: $condition) {
    id
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    communitypostID
    createdAt
    updatedAt
    communityLikesUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommunityLikesMutationVariables,
  APITypes.UpdateCommunityLikesMutation
>;
export const deleteCommunityLikes = /* GraphQL */ `mutation DeleteCommunityLikes(
  $input: DeleteCommunityLikesInput!
  $condition: ModelCommunityLikesConditionInput
) {
  deleteCommunityLikes(input: $input, condition: $condition) {
    id
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    communitypostID
    createdAt
    updatedAt
    communityLikesUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommunityLikesMutationVariables,
  APITypes.DeleteCommunityLikesMutation
>;
export const createCommunityComment = /* GraphQL */ `mutation CreateCommunityComment(
  $input: CreateCommunityCommentInput!
  $condition: ModelCommunityCommentConditionInput
) {
  createCommunityComment(input: $input, condition: $condition) {
    id
    text
    likes
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    communitypostID
    createdAt
    updatedAt
    communityCommentUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommunityCommentMutationVariables,
  APITypes.CreateCommunityCommentMutation
>;
export const updateCommunityComment = /* GraphQL */ `mutation UpdateCommunityComment(
  $input: UpdateCommunityCommentInput!
  $condition: ModelCommunityCommentConditionInput
) {
  updateCommunityComment(input: $input, condition: $condition) {
    id
    text
    likes
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    communitypostID
    createdAt
    updatedAt
    communityCommentUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommunityCommentMutationVariables,
  APITypes.UpdateCommunityCommentMutation
>;
export const deleteCommunityComment = /* GraphQL */ `mutation DeleteCommunityComment(
  $input: DeleteCommunityCommentInput!
  $condition: ModelCommunityCommentConditionInput
) {
  deleteCommunityComment(input: $input, condition: $condition) {
    id
    text
    likes
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    communitypostID
    createdAt
    updatedAt
    communityCommentUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommunityCommentMutationVariables,
  APITypes.DeleteCommunityCommentMutation
>;
export const createCommunityPost = /* GraphQL */ `mutation CreateCommunityPost(
  $input: CreateCommunityPostInput!
  $condition: ModelCommunityPostConditionInput
) {
  createCommunityPost(input: $input, condition: $condition) {
    id
    text
    mediaS3Key
    dateTime
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    CommunityComments {
      items {
        id
        text
        likes
        communitypostID
        createdAt
        updatedAt
        communityCommentUserId
        __typename
      }
      nextToken
      __typename
    }
    CommunityLikes {
      items {
        id
        communitypostID
        createdAt
        updatedAt
        communityLikesUserId
        __typename
      }
      nextToken
      __typename
    }
    CommunityPostPoll {
      items {
        id
        text
        communitypostID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    communitygroupID
    Guidance {
      id
      title
      shortDesc
      longDesc
      thumbURL
      date
      time
      estTime
      deleted
      GuidanceTypes {
        id
        name
        description
        colour
        deleted
        createdAt
        updatedAt
        __typename
      }
      location
      author
      S3ObjectKey
      GuidanceContents {
        nextToken
        __typename
      }
      status
      active
      lowerCaseTitle
      lowerCaseShortDesc
      scheduleDateTime
      createdAt
      updatedAt
      guidanceGuidanceTypesId
      __typename
    }
    needsModeration
    moderationLabel
    moderationScore
    createdAt
    updatedAt
    communityPostUserId
    communityPostGuidanceId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommunityPostMutationVariables,
  APITypes.CreateCommunityPostMutation
>;
export const updateCommunityPost = /* GraphQL */ `mutation UpdateCommunityPost(
  $input: UpdateCommunityPostInput!
  $condition: ModelCommunityPostConditionInput
) {
  updateCommunityPost(input: $input, condition: $condition) {
    id
    text
    mediaS3Key
    dateTime
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    CommunityComments {
      items {
        id
        text
        likes
        communitypostID
        createdAt
        updatedAt
        communityCommentUserId
        __typename
      }
      nextToken
      __typename
    }
    CommunityLikes {
      items {
        id
        communitypostID
        createdAt
        updatedAt
        communityLikesUserId
        __typename
      }
      nextToken
      __typename
    }
    CommunityPostPoll {
      items {
        id
        text
        communitypostID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    communitygroupID
    Guidance {
      id
      title
      shortDesc
      longDesc
      thumbURL
      date
      time
      estTime
      deleted
      GuidanceTypes {
        id
        name
        description
        colour
        deleted
        createdAt
        updatedAt
        __typename
      }
      location
      author
      S3ObjectKey
      GuidanceContents {
        nextToken
        __typename
      }
      status
      active
      lowerCaseTitle
      lowerCaseShortDesc
      scheduleDateTime
      createdAt
      updatedAt
      guidanceGuidanceTypesId
      __typename
    }
    needsModeration
    moderationLabel
    moderationScore
    createdAt
    updatedAt
    communityPostUserId
    communityPostGuidanceId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommunityPostMutationVariables,
  APITypes.UpdateCommunityPostMutation
>;
export const deleteCommunityPost = /* GraphQL */ `mutation DeleteCommunityPost(
  $input: DeleteCommunityPostInput!
  $condition: ModelCommunityPostConditionInput
) {
  deleteCommunityPost(input: $input, condition: $condition) {
    id
    text
    mediaS3Key
    dateTime
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    CommunityComments {
      items {
        id
        text
        likes
        communitypostID
        createdAt
        updatedAt
        communityCommentUserId
        __typename
      }
      nextToken
      __typename
    }
    CommunityLikes {
      items {
        id
        communitypostID
        createdAt
        updatedAt
        communityLikesUserId
        __typename
      }
      nextToken
      __typename
    }
    CommunityPostPoll {
      items {
        id
        text
        communitypostID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    communitygroupID
    Guidance {
      id
      title
      shortDesc
      longDesc
      thumbURL
      date
      time
      estTime
      deleted
      GuidanceTypes {
        id
        name
        description
        colour
        deleted
        createdAt
        updatedAt
        __typename
      }
      location
      author
      S3ObjectKey
      GuidanceContents {
        nextToken
        __typename
      }
      status
      active
      lowerCaseTitle
      lowerCaseShortDesc
      scheduleDateTime
      createdAt
      updatedAt
      guidanceGuidanceTypesId
      __typename
    }
    needsModeration
    moderationLabel
    moderationScore
    createdAt
    updatedAt
    communityPostUserId
    communityPostGuidanceId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommunityPostMutationVariables,
  APITypes.DeleteCommunityPostMutation
>;
export const createCalendarItemType = /* GraphQL */ `mutation CreateCalendarItemType(
  $input: CreateCalendarItemTypeInput!
  $condition: ModelCalendarItemTypeConditionInput
) {
  createCalendarItemType(input: $input, condition: $condition) {
    id
    name
    colour
    deleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCalendarItemTypeMutationVariables,
  APITypes.CreateCalendarItemTypeMutation
>;
export const updateCalendarItemType = /* GraphQL */ `mutation UpdateCalendarItemType(
  $input: UpdateCalendarItemTypeInput!
  $condition: ModelCalendarItemTypeConditionInput
) {
  updateCalendarItemType(input: $input, condition: $condition) {
    id
    name
    colour
    deleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCalendarItemTypeMutationVariables,
  APITypes.UpdateCalendarItemTypeMutation
>;
export const deleteCalendarItemType = /* GraphQL */ `mutation DeleteCalendarItemType(
  $input: DeleteCalendarItemTypeInput!
  $condition: ModelCalendarItemTypeConditionInput
) {
  deleteCalendarItemType(input: $input, condition: $condition) {
    id
    name
    colour
    deleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCalendarItemTypeMutationVariables,
  APITypes.DeleteCalendarItemTypeMutation
>;
export const createRoadMapItems = /* GraphQL */ `mutation CreateRoadMapItems(
  $input: CreateRoadMapItemsInput!
  $condition: ModelRoadMapItemsConditionInput
) {
  createRoadMapItems(input: $input, condition: $condition) {
    id
    text
    description
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRoadMapItemsMutationVariables,
  APITypes.CreateRoadMapItemsMutation
>;
export const updateRoadMapItems = /* GraphQL */ `mutation UpdateRoadMapItems(
  $input: UpdateRoadMapItemsInput!
  $condition: ModelRoadMapItemsConditionInput
) {
  updateRoadMapItems(input: $input, condition: $condition) {
    id
    text
    description
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRoadMapItemsMutationVariables,
  APITypes.UpdateRoadMapItemsMutation
>;
export const deleteRoadMapItems = /* GraphQL */ `mutation DeleteRoadMapItems(
  $input: DeleteRoadMapItemsInput!
  $condition: ModelRoadMapItemsConditionInput
) {
  deleteRoadMapItems(input: $input, condition: $condition) {
    id
    text
    description
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRoadMapItemsMutationVariables,
  APITypes.DeleteRoadMapItemsMutation
>;
export const createManualNotificationsUser = /* GraphQL */ `mutation CreateManualNotificationsUser(
  $input: CreateManualNotificationsUserInput!
  $condition: ModelManualNotificationsUserConditionInput
) {
  createManualNotificationsUser(input: $input, condition: $condition) {
    id
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    seen
    createdAt
    updatedAt
    manualNotificationsUserUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateManualNotificationsUserMutationVariables,
  APITypes.CreateManualNotificationsUserMutation
>;
export const updateManualNotificationsUser = /* GraphQL */ `mutation UpdateManualNotificationsUser(
  $input: UpdateManualNotificationsUserInput!
  $condition: ModelManualNotificationsUserConditionInput
) {
  updateManualNotificationsUser(input: $input, condition: $condition) {
    id
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    seen
    createdAt
    updatedAt
    manualNotificationsUserUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateManualNotificationsUserMutationVariables,
  APITypes.UpdateManualNotificationsUserMutation
>;
export const deleteManualNotificationsUser = /* GraphQL */ `mutation DeleteManualNotificationsUser(
  $input: DeleteManualNotificationsUserInput!
  $condition: ModelManualNotificationsUserConditionInput
) {
  deleteManualNotificationsUser(input: $input, condition: $condition) {
    id
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    seen
    createdAt
    updatedAt
    manualNotificationsUserUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteManualNotificationsUserMutationVariables,
  APITypes.DeleteManualNotificationsUserMutation
>;
export const createManualNotifications = /* GraphQL */ `mutation CreateManualNotifications(
  $input: CreateManualNotificationsInput!
  $condition: ModelManualNotificationsConditionInput
) {
  createManualNotifications(input: $input, condition: $condition) {
    id
    title
    shortText
    Guidance {
      id
      title
      shortDesc
      longDesc
      thumbURL
      date
      time
      estTime
      deleted
      GuidanceTypes {
        id
        name
        description
        colour
        deleted
        createdAt
        updatedAt
        __typename
      }
      location
      author
      S3ObjectKey
      GuidanceContents {
        nextToken
        __typename
      }
      status
      active
      lowerCaseTitle
      lowerCaseShortDesc
      scheduleDateTime
      createdAt
      updatedAt
      guidanceGuidanceTypesId
      __typename
    }
    ManualNotificationsUser {
      id
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      seen
      createdAt
      updatedAt
      manualNotificationsUserUserId
      __typename
    }
    createdAt
    updatedAt
    manualNotificationsGuidanceId
    manualNotificationsManualNotificationsUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateManualNotificationsMutationVariables,
  APITypes.CreateManualNotificationsMutation
>;
export const updateManualNotifications = /* GraphQL */ `mutation UpdateManualNotifications(
  $input: UpdateManualNotificationsInput!
  $condition: ModelManualNotificationsConditionInput
) {
  updateManualNotifications(input: $input, condition: $condition) {
    id
    title
    shortText
    Guidance {
      id
      title
      shortDesc
      longDesc
      thumbURL
      date
      time
      estTime
      deleted
      GuidanceTypes {
        id
        name
        description
        colour
        deleted
        createdAt
        updatedAt
        __typename
      }
      location
      author
      S3ObjectKey
      GuidanceContents {
        nextToken
        __typename
      }
      status
      active
      lowerCaseTitle
      lowerCaseShortDesc
      scheduleDateTime
      createdAt
      updatedAt
      guidanceGuidanceTypesId
      __typename
    }
    ManualNotificationsUser {
      id
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      seen
      createdAt
      updatedAt
      manualNotificationsUserUserId
      __typename
    }
    createdAt
    updatedAt
    manualNotificationsGuidanceId
    manualNotificationsManualNotificationsUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateManualNotificationsMutationVariables,
  APITypes.UpdateManualNotificationsMutation
>;
export const deleteManualNotifications = /* GraphQL */ `mutation DeleteManualNotifications(
  $input: DeleteManualNotificationsInput!
  $condition: ModelManualNotificationsConditionInput
) {
  deleteManualNotifications(input: $input, condition: $condition) {
    id
    title
    shortText
    Guidance {
      id
      title
      shortDesc
      longDesc
      thumbURL
      date
      time
      estTime
      deleted
      GuidanceTypes {
        id
        name
        description
        colour
        deleted
        createdAt
        updatedAt
        __typename
      }
      location
      author
      S3ObjectKey
      GuidanceContents {
        nextToken
        __typename
      }
      status
      active
      lowerCaseTitle
      lowerCaseShortDesc
      scheduleDateTime
      createdAt
      updatedAt
      guidanceGuidanceTypesId
      __typename
    }
    ManualNotificationsUser {
      id
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      seen
      createdAt
      updatedAt
      manualNotificationsUserUserId
      __typename
    }
    createdAt
    updatedAt
    manualNotificationsGuidanceId
    manualNotificationsManualNotificationsUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteManualNotificationsMutationVariables,
  APITypes.DeleteManualNotificationsMutation
>;
export const createQuestionAnswersUsers = /* GraphQL */ `mutation CreateQuestionAnswersUsers(
  $input: CreateQuestionAnswersUsersInput!
  $condition: ModelQuestionAnswersUsersConditionInput
) {
  createQuestionAnswersUsers(input: $input, condition: $condition) {
    id
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    QuestionAnswers {
      id
      answer
      helpText
      Questions {
        id
        question
        helpText
        deleted
        isFirstQuestion
        questionNumber
        createdAt
        updatedAt
        questionsQuestionGroupId
        __typename
      }
      nextQuestionID
      deleted
      GuidanceContent {
        id
        title
        shortDesc
        longDesc
        thumbURL
        date
        time
        estTime
        deleted
        location
        author
        S3ObjectKey
        status
        active
        lowerCaseTitle
        lowerCaseShortDesc
        scheduleDateTime
        createdAt
        updatedAt
        guidanceGuidanceTypesId
        __typename
      }
      RoadMapItems {
        id
        text
        description
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      questionAnswersQuestionsId
      questionAnswersGuidanceContentId
      questionAnswersRoadMapItemsId
      __typename
    }
    Questions {
      id
      question
      helpText
      QuestionGroup {
        id
        name
        description
        imageS3ObjectKey
        deleted
        type
        status
        createdAt
        updatedAt
        questionGroupQuestionGroupTypesId
        __typename
      }
      deleted
      isFirstQuestion
      questionNumber
      createdAt
      updatedAt
      questionsQuestionGroupId
      __typename
    }
    freeText
    complete
    createdAt
    updatedAt
    questionAnswersUsersUserId
    questionAnswersUsersQuestionAnswersId
    questionAnswersUsersQuestionsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuestionAnswersUsersMutationVariables,
  APITypes.CreateQuestionAnswersUsersMutation
>;
export const updateQuestionAnswersUsers = /* GraphQL */ `mutation UpdateQuestionAnswersUsers(
  $input: UpdateQuestionAnswersUsersInput!
  $condition: ModelQuestionAnswersUsersConditionInput
) {
  updateQuestionAnswersUsers(input: $input, condition: $condition) {
    id
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    QuestionAnswers {
      id
      answer
      helpText
      Questions {
        id
        question
        helpText
        deleted
        isFirstQuestion
        questionNumber
        createdAt
        updatedAt
        questionsQuestionGroupId
        __typename
      }
      nextQuestionID
      deleted
      GuidanceContent {
        id
        title
        shortDesc
        longDesc
        thumbURL
        date
        time
        estTime
        deleted
        location
        author
        S3ObjectKey
        status
        active
        lowerCaseTitle
        lowerCaseShortDesc
        scheduleDateTime
        createdAt
        updatedAt
        guidanceGuidanceTypesId
        __typename
      }
      RoadMapItems {
        id
        text
        description
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      questionAnswersQuestionsId
      questionAnswersGuidanceContentId
      questionAnswersRoadMapItemsId
      __typename
    }
    Questions {
      id
      question
      helpText
      QuestionGroup {
        id
        name
        description
        imageS3ObjectKey
        deleted
        type
        status
        createdAt
        updatedAt
        questionGroupQuestionGroupTypesId
        __typename
      }
      deleted
      isFirstQuestion
      questionNumber
      createdAt
      updatedAt
      questionsQuestionGroupId
      __typename
    }
    freeText
    complete
    createdAt
    updatedAt
    questionAnswersUsersUserId
    questionAnswersUsersQuestionAnswersId
    questionAnswersUsersQuestionsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuestionAnswersUsersMutationVariables,
  APITypes.UpdateQuestionAnswersUsersMutation
>;
export const deleteQuestionAnswersUsers = /* GraphQL */ `mutation DeleteQuestionAnswersUsers(
  $input: DeleteQuestionAnswersUsersInput!
  $condition: ModelQuestionAnswersUsersConditionInput
) {
  deleteQuestionAnswersUsers(input: $input, condition: $condition) {
    id
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    QuestionAnswers {
      id
      answer
      helpText
      Questions {
        id
        question
        helpText
        deleted
        isFirstQuestion
        questionNumber
        createdAt
        updatedAt
        questionsQuestionGroupId
        __typename
      }
      nextQuestionID
      deleted
      GuidanceContent {
        id
        title
        shortDesc
        longDesc
        thumbURL
        date
        time
        estTime
        deleted
        location
        author
        S3ObjectKey
        status
        active
        lowerCaseTitle
        lowerCaseShortDesc
        scheduleDateTime
        createdAt
        updatedAt
        guidanceGuidanceTypesId
        __typename
      }
      RoadMapItems {
        id
        text
        description
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      questionAnswersQuestionsId
      questionAnswersGuidanceContentId
      questionAnswersRoadMapItemsId
      __typename
    }
    Questions {
      id
      question
      helpText
      QuestionGroup {
        id
        name
        description
        imageS3ObjectKey
        deleted
        type
        status
        createdAt
        updatedAt
        questionGroupQuestionGroupTypesId
        __typename
      }
      deleted
      isFirstQuestion
      questionNumber
      createdAt
      updatedAt
      questionsQuestionGroupId
      __typename
    }
    freeText
    complete
    createdAt
    updatedAt
    questionAnswersUsersUserId
    questionAnswersUsersQuestionAnswersId
    questionAnswersUsersQuestionsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuestionAnswersUsersMutationVariables,
  APITypes.DeleteQuestionAnswersUsersMutation
>;
export const createQuestionAnswers = /* GraphQL */ `mutation CreateQuestionAnswers(
  $input: CreateQuestionAnswersInput!
  $condition: ModelQuestionAnswersConditionInput
) {
  createQuestionAnswers(input: $input, condition: $condition) {
    id
    answer
    helpText
    Questions {
      id
      question
      helpText
      QuestionGroup {
        id
        name
        description
        imageS3ObjectKey
        deleted
        type
        status
        createdAt
        updatedAt
        questionGroupQuestionGroupTypesId
        __typename
      }
      deleted
      isFirstQuestion
      questionNumber
      createdAt
      updatedAt
      questionsQuestionGroupId
      __typename
    }
    nextQuestionID
    deleted
    GuidanceContent {
      id
      title
      shortDesc
      longDesc
      thumbURL
      date
      time
      estTime
      deleted
      GuidanceTypes {
        id
        name
        description
        colour
        deleted
        createdAt
        updatedAt
        __typename
      }
      location
      author
      S3ObjectKey
      GuidanceContents {
        nextToken
        __typename
      }
      status
      active
      lowerCaseTitle
      lowerCaseShortDesc
      scheduleDateTime
      createdAt
      updatedAt
      guidanceGuidanceTypesId
      __typename
    }
    RoadMapItems {
      id
      text
      description
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    questionAnswersQuestionsId
    questionAnswersGuidanceContentId
    questionAnswersRoadMapItemsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuestionAnswersMutationVariables,
  APITypes.CreateQuestionAnswersMutation
>;
export const updateQuestionAnswers = /* GraphQL */ `mutation UpdateQuestionAnswers(
  $input: UpdateQuestionAnswersInput!
  $condition: ModelQuestionAnswersConditionInput
) {
  updateQuestionAnswers(input: $input, condition: $condition) {
    id
    answer
    helpText
    Questions {
      id
      question
      helpText
      QuestionGroup {
        id
        name
        description
        imageS3ObjectKey
        deleted
        type
        status
        createdAt
        updatedAt
        questionGroupQuestionGroupTypesId
        __typename
      }
      deleted
      isFirstQuestion
      questionNumber
      createdAt
      updatedAt
      questionsQuestionGroupId
      __typename
    }
    nextQuestionID
    deleted
    GuidanceContent {
      id
      title
      shortDesc
      longDesc
      thumbURL
      date
      time
      estTime
      deleted
      GuidanceTypes {
        id
        name
        description
        colour
        deleted
        createdAt
        updatedAt
        __typename
      }
      location
      author
      S3ObjectKey
      GuidanceContents {
        nextToken
        __typename
      }
      status
      active
      lowerCaseTitle
      lowerCaseShortDesc
      scheduleDateTime
      createdAt
      updatedAt
      guidanceGuidanceTypesId
      __typename
    }
    RoadMapItems {
      id
      text
      description
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    questionAnswersQuestionsId
    questionAnswersGuidanceContentId
    questionAnswersRoadMapItemsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuestionAnswersMutationVariables,
  APITypes.UpdateQuestionAnswersMutation
>;
export const deleteQuestionAnswers = /* GraphQL */ `mutation DeleteQuestionAnswers(
  $input: DeleteQuestionAnswersInput!
  $condition: ModelQuestionAnswersConditionInput
) {
  deleteQuestionAnswers(input: $input, condition: $condition) {
    id
    answer
    helpText
    Questions {
      id
      question
      helpText
      QuestionGroup {
        id
        name
        description
        imageS3ObjectKey
        deleted
        type
        status
        createdAt
        updatedAt
        questionGroupQuestionGroupTypesId
        __typename
      }
      deleted
      isFirstQuestion
      questionNumber
      createdAt
      updatedAt
      questionsQuestionGroupId
      __typename
    }
    nextQuestionID
    deleted
    GuidanceContent {
      id
      title
      shortDesc
      longDesc
      thumbURL
      date
      time
      estTime
      deleted
      GuidanceTypes {
        id
        name
        description
        colour
        deleted
        createdAt
        updatedAt
        __typename
      }
      location
      author
      S3ObjectKey
      GuidanceContents {
        nextToken
        __typename
      }
      status
      active
      lowerCaseTitle
      lowerCaseShortDesc
      scheduleDateTime
      createdAt
      updatedAt
      guidanceGuidanceTypesId
      __typename
    }
    RoadMapItems {
      id
      text
      description
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    questionAnswersQuestionsId
    questionAnswersGuidanceContentId
    questionAnswersRoadMapItemsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuestionAnswersMutationVariables,
  APITypes.DeleteQuestionAnswersMutation
>;
export const createQuestions = /* GraphQL */ `mutation CreateQuestions(
  $input: CreateQuestionsInput!
  $condition: ModelQuestionsConditionInput
) {
  createQuestions(input: $input, condition: $condition) {
    id
    question
    helpText
    QuestionGroup {
      id
      name
      description
      imageS3ObjectKey
      deleted
      type
      status
      QuestionGroupTypes {
        id
        name
        iconS3URL
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      questionGroupQuestionGroupTypesId
      __typename
    }
    deleted
    isFirstQuestion
    questionNumber
    createdAt
    updatedAt
    questionsQuestionGroupId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuestionsMutationVariables,
  APITypes.CreateQuestionsMutation
>;
export const updateQuestions = /* GraphQL */ `mutation UpdateQuestions(
  $input: UpdateQuestionsInput!
  $condition: ModelQuestionsConditionInput
) {
  updateQuestions(input: $input, condition: $condition) {
    id
    question
    helpText
    QuestionGroup {
      id
      name
      description
      imageS3ObjectKey
      deleted
      type
      status
      QuestionGroupTypes {
        id
        name
        iconS3URL
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      questionGroupQuestionGroupTypesId
      __typename
    }
    deleted
    isFirstQuestion
    questionNumber
    createdAt
    updatedAt
    questionsQuestionGroupId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuestionsMutationVariables,
  APITypes.UpdateQuestionsMutation
>;
export const deleteQuestions = /* GraphQL */ `mutation DeleteQuestions(
  $input: DeleteQuestionsInput!
  $condition: ModelQuestionsConditionInput
) {
  deleteQuestions(input: $input, condition: $condition) {
    id
    question
    helpText
    QuestionGroup {
      id
      name
      description
      imageS3ObjectKey
      deleted
      type
      status
      QuestionGroupTypes {
        id
        name
        iconS3URL
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      questionGroupQuestionGroupTypesId
      __typename
    }
    deleted
    isFirstQuestion
    questionNumber
    createdAt
    updatedAt
    questionsQuestionGroupId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuestionsMutationVariables,
  APITypes.DeleteQuestionsMutation
>;
export const createQuestionGroup = /* GraphQL */ `mutation CreateQuestionGroup(
  $input: CreateQuestionGroupInput!
  $condition: ModelQuestionGroupConditionInput
) {
  createQuestionGroup(input: $input, condition: $condition) {
    id
    name
    description
    imageS3ObjectKey
    deleted
    type
    status
    QuestionGroupTypes {
      id
      name
      iconS3URL
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    questionGroupQuestionGroupTypesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuestionGroupMutationVariables,
  APITypes.CreateQuestionGroupMutation
>;
export const updateQuestionGroup = /* GraphQL */ `mutation UpdateQuestionGroup(
  $input: UpdateQuestionGroupInput!
  $condition: ModelQuestionGroupConditionInput
) {
  updateQuestionGroup(input: $input, condition: $condition) {
    id
    name
    description
    imageS3ObjectKey
    deleted
    type
    status
    QuestionGroupTypes {
      id
      name
      iconS3URL
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    questionGroupQuestionGroupTypesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuestionGroupMutationVariables,
  APITypes.UpdateQuestionGroupMutation
>;
export const deleteQuestionGroup = /* GraphQL */ `mutation DeleteQuestionGroup(
  $input: DeleteQuestionGroupInput!
  $condition: ModelQuestionGroupConditionInput
) {
  deleteQuestionGroup(input: $input, condition: $condition) {
    id
    name
    description
    imageS3ObjectKey
    deleted
    type
    status
    QuestionGroupTypes {
      id
      name
      iconS3URL
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    questionGroupQuestionGroupTypesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuestionGroupMutationVariables,
  APITypes.DeleteQuestionGroupMutation
>;
export const createBookmarks = /* GraphQL */ `mutation CreateBookmarks(
  $input: CreateBookmarksInput!
  $condition: ModelBookmarksConditionInput
) {
  createBookmarks(input: $input, condition: $condition) {
    id
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    Guidance {
      id
      title
      shortDesc
      longDesc
      thumbURL
      date
      time
      estTime
      deleted
      GuidanceTypes {
        id
        name
        description
        colour
        deleted
        createdAt
        updatedAt
        __typename
      }
      location
      author
      S3ObjectKey
      GuidanceContents {
        nextToken
        __typename
      }
      status
      active
      lowerCaseTitle
      lowerCaseShortDesc
      scheduleDateTime
      createdAt
      updatedAt
      guidanceGuidanceTypesId
      __typename
    }
    deleted
    createdAt
    updatedAt
    bookmarksUserId
    bookmarksGuidanceId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBookmarksMutationVariables,
  APITypes.CreateBookmarksMutation
>;
export const updateBookmarks = /* GraphQL */ `mutation UpdateBookmarks(
  $input: UpdateBookmarksInput!
  $condition: ModelBookmarksConditionInput
) {
  updateBookmarks(input: $input, condition: $condition) {
    id
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    Guidance {
      id
      title
      shortDesc
      longDesc
      thumbURL
      date
      time
      estTime
      deleted
      GuidanceTypes {
        id
        name
        description
        colour
        deleted
        createdAt
        updatedAt
        __typename
      }
      location
      author
      S3ObjectKey
      GuidanceContents {
        nextToken
        __typename
      }
      status
      active
      lowerCaseTitle
      lowerCaseShortDesc
      scheduleDateTime
      createdAt
      updatedAt
      guidanceGuidanceTypesId
      __typename
    }
    deleted
    createdAt
    updatedAt
    bookmarksUserId
    bookmarksGuidanceId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBookmarksMutationVariables,
  APITypes.UpdateBookmarksMutation
>;
export const deleteBookmarks = /* GraphQL */ `mutation DeleteBookmarks(
  $input: DeleteBookmarksInput!
  $condition: ModelBookmarksConditionInput
) {
  deleteBookmarks(input: $input, condition: $condition) {
    id
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    Guidance {
      id
      title
      shortDesc
      longDesc
      thumbURL
      date
      time
      estTime
      deleted
      GuidanceTypes {
        id
        name
        description
        colour
        deleted
        createdAt
        updatedAt
        __typename
      }
      location
      author
      S3ObjectKey
      GuidanceContents {
        nextToken
        __typename
      }
      status
      active
      lowerCaseTitle
      lowerCaseShortDesc
      scheduleDateTime
      createdAt
      updatedAt
      guidanceGuidanceTypesId
      __typename
    }
    deleted
    createdAt
    updatedAt
    bookmarksUserId
    bookmarksGuidanceId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBookmarksMutationVariables,
  APITypes.DeleteBookmarksMutation
>;
export const createActivityUserApp = /* GraphQL */ `mutation CreateActivityUserApp(
  $input: CreateActivityUserAppInput!
  $condition: ModelActivityUserAppConditionInput
) {
  createActivityUserApp(input: $input, condition: $condition) {
    id
    section
    page
    details
    userGUID
    userEmail
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateActivityUserAppMutationVariables,
  APITypes.CreateActivityUserAppMutation
>;
export const updateActivityUserApp = /* GraphQL */ `mutation UpdateActivityUserApp(
  $input: UpdateActivityUserAppInput!
  $condition: ModelActivityUserAppConditionInput
) {
  updateActivityUserApp(input: $input, condition: $condition) {
    id
    section
    page
    details
    userGUID
    userEmail
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateActivityUserAppMutationVariables,
  APITypes.UpdateActivityUserAppMutation
>;
export const deleteActivityUserApp = /* GraphQL */ `mutation DeleteActivityUserApp(
  $input: DeleteActivityUserAppInput!
  $condition: ModelActivityUserAppConditionInput
) {
  deleteActivityUserApp(input: $input, condition: $condition) {
    id
    section
    page
    details
    userGUID
    userEmail
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteActivityUserAppMutationVariables,
  APITypes.DeleteActivityUserAppMutation
>;
export const createGuidanceContent = /* GraphQL */ `mutation CreateGuidanceContent(
  $input: CreateGuidanceContentInput!
  $condition: ModelGuidanceContentConditionInput
) {
  createGuidanceContent(input: $input, condition: $condition) {
    id
    title
    guidanceID
    content
    link
    order
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGuidanceContentMutationVariables,
  APITypes.CreateGuidanceContentMutation
>;
export const updateGuidanceContent = /* GraphQL */ `mutation UpdateGuidanceContent(
  $input: UpdateGuidanceContentInput!
  $condition: ModelGuidanceContentConditionInput
) {
  updateGuidanceContent(input: $input, condition: $condition) {
    id
    title
    guidanceID
    content
    link
    order
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGuidanceContentMutationVariables,
  APITypes.UpdateGuidanceContentMutation
>;
export const deleteGuidanceContent = /* GraphQL */ `mutation DeleteGuidanceContent(
  $input: DeleteGuidanceContentInput!
  $condition: ModelGuidanceContentConditionInput
) {
  deleteGuidanceContent(input: $input, condition: $condition) {
    id
    title
    guidanceID
    content
    link
    order
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGuidanceContentMutationVariables,
  APITypes.DeleteGuidanceContentMutation
>;
export const createGuidanceTypes = /* GraphQL */ `mutation CreateGuidanceTypes(
  $input: CreateGuidanceTypesInput!
  $condition: ModelGuidanceTypesConditionInput
) {
  createGuidanceTypes(input: $input, condition: $condition) {
    id
    name
    description
    colour
    deleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGuidanceTypesMutationVariables,
  APITypes.CreateGuidanceTypesMutation
>;
export const updateGuidanceTypes = /* GraphQL */ `mutation UpdateGuidanceTypes(
  $input: UpdateGuidanceTypesInput!
  $condition: ModelGuidanceTypesConditionInput
) {
  updateGuidanceTypes(input: $input, condition: $condition) {
    id
    name
    description
    colour
    deleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGuidanceTypesMutationVariables,
  APITypes.UpdateGuidanceTypesMutation
>;
export const deleteGuidanceTypes = /* GraphQL */ `mutation DeleteGuidanceTypes(
  $input: DeleteGuidanceTypesInput!
  $condition: ModelGuidanceTypesConditionInput
) {
  deleteGuidanceTypes(input: $input, condition: $condition) {
    id
    name
    description
    colour
    deleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGuidanceTypesMutationVariables,
  APITypes.DeleteGuidanceTypesMutation
>;
export const createGuidance = /* GraphQL */ `mutation CreateGuidance(
  $input: CreateGuidanceInput!
  $condition: ModelGuidanceConditionInput
) {
  createGuidance(input: $input, condition: $condition) {
    id
    title
    shortDesc
    longDesc
    thumbURL
    date
    time
    estTime
    deleted
    GuidanceTypes {
      id
      name
      description
      colour
      deleted
      createdAt
      updatedAt
      __typename
    }
    location
    author
    S3ObjectKey
    GuidanceContents {
      items {
        id
        title
        guidanceID
        content
        link
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    status
    active
    lowerCaseTitle
    lowerCaseShortDesc
    scheduleDateTime
    createdAt
    updatedAt
    guidanceGuidanceTypesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGuidanceMutationVariables,
  APITypes.CreateGuidanceMutation
>;
export const updateGuidance = /* GraphQL */ `mutation UpdateGuidance(
  $input: UpdateGuidanceInput!
  $condition: ModelGuidanceConditionInput
) {
  updateGuidance(input: $input, condition: $condition) {
    id
    title
    shortDesc
    longDesc
    thumbURL
    date
    time
    estTime
    deleted
    GuidanceTypes {
      id
      name
      description
      colour
      deleted
      createdAt
      updatedAt
      __typename
    }
    location
    author
    S3ObjectKey
    GuidanceContents {
      items {
        id
        title
        guidanceID
        content
        link
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    status
    active
    lowerCaseTitle
    lowerCaseShortDesc
    scheduleDateTime
    createdAt
    updatedAt
    guidanceGuidanceTypesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGuidanceMutationVariables,
  APITypes.UpdateGuidanceMutation
>;
export const deleteGuidance = /* GraphQL */ `mutation DeleteGuidance(
  $input: DeleteGuidanceInput!
  $condition: ModelGuidanceConditionInput
) {
  deleteGuidance(input: $input, condition: $condition) {
    id
    title
    shortDesc
    longDesc
    thumbURL
    date
    time
    estTime
    deleted
    GuidanceTypes {
      id
      name
      description
      colour
      deleted
      createdAt
      updatedAt
      __typename
    }
    location
    author
    S3ObjectKey
    GuidanceContents {
      items {
        id
        title
        guidanceID
        content
        link
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    status
    active
    lowerCaseTitle
    lowerCaseShortDesc
    scheduleDateTime
    createdAt
    updatedAt
    guidanceGuidanceTypesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGuidanceMutationVariables,
  APITypes.DeleteGuidanceMutation
>;
export const createCalendar = /* GraphQL */ `mutation CreateCalendar(
  $input: CreateCalendarInput!
  $condition: ModelCalendarConditionInput
) {
  createCalendar(input: $input, condition: $condition) {
    id
    title
    description
    datetime
    location
    Guidance {
      id
      title
      shortDesc
      longDesc
      thumbURL
      date
      time
      estTime
      deleted
      GuidanceTypes {
        id
        name
        description
        colour
        deleted
        createdAt
        updatedAt
        __typename
      }
      location
      author
      S3ObjectKey
      GuidanceContents {
        nextToken
        __typename
      }
      status
      active
      lowerCaseTitle
      lowerCaseShortDesc
      scheduleDateTime
      createdAt
      updatedAt
      guidanceGuidanceTypesId
      __typename
    }
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    done
    CalendarItemType {
      id
      name
      colour
      deleted
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    calendarGuidanceId
    calendarUserId
    calendarCalendarItemTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCalendarMutationVariables,
  APITypes.CreateCalendarMutation
>;
export const updateCalendar = /* GraphQL */ `mutation UpdateCalendar(
  $input: UpdateCalendarInput!
  $condition: ModelCalendarConditionInput
) {
  updateCalendar(input: $input, condition: $condition) {
    id
    title
    description
    datetime
    location
    Guidance {
      id
      title
      shortDesc
      longDesc
      thumbURL
      date
      time
      estTime
      deleted
      GuidanceTypes {
        id
        name
        description
        colour
        deleted
        createdAt
        updatedAt
        __typename
      }
      location
      author
      S3ObjectKey
      GuidanceContents {
        nextToken
        __typename
      }
      status
      active
      lowerCaseTitle
      lowerCaseShortDesc
      scheduleDateTime
      createdAt
      updatedAt
      guidanceGuidanceTypesId
      __typename
    }
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    done
    CalendarItemType {
      id
      name
      colour
      deleted
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    calendarGuidanceId
    calendarUserId
    calendarCalendarItemTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCalendarMutationVariables,
  APITypes.UpdateCalendarMutation
>;
export const deleteCalendar = /* GraphQL */ `mutation DeleteCalendar(
  $input: DeleteCalendarInput!
  $condition: ModelCalendarConditionInput
) {
  deleteCalendar(input: $input, condition: $condition) {
    id
    title
    description
    datetime
    location
    Guidance {
      id
      title
      shortDesc
      longDesc
      thumbURL
      date
      time
      estTime
      deleted
      GuidanceTypes {
        id
        name
        description
        colour
        deleted
        createdAt
        updatedAt
        __typename
      }
      location
      author
      S3ObjectKey
      GuidanceContents {
        nextToken
        __typename
      }
      status
      active
      lowerCaseTitle
      lowerCaseShortDesc
      scheduleDateTime
      createdAt
      updatedAt
      guidanceGuidanceTypesId
      __typename
    }
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    done
    CalendarItemType {
      id
      name
      colour
      deleted
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    calendarGuidanceId
    calendarUserId
    calendarCalendarItemTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCalendarMutationVariables,
  APITypes.DeleteCalendarMutation
>;
export const createArticles = /* GraphQL */ `mutation CreateArticles(
  $input: CreateArticlesInput!
  $condition: ModelArticlesConditionInput
) {
  createArticles(input: $input, condition: $condition) {
    id
    title
    description
    imageURL
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateArticlesMutationVariables,
  APITypes.CreateArticlesMutation
>;
export const updateArticles = /* GraphQL */ `mutation UpdateArticles(
  $input: UpdateArticlesInput!
  $condition: ModelArticlesConditionInput
) {
  updateArticles(input: $input, condition: $condition) {
    id
    title
    description
    imageURL
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateArticlesMutationVariables,
  APITypes.UpdateArticlesMutation
>;
export const deleteArticles = /* GraphQL */ `mutation DeleteArticles(
  $input: DeleteArticlesInput!
  $condition: ModelArticlesConditionInput
) {
  deleteArticles(input: $input, condition: $condition) {
    id
    title
    description
    imageURL
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteArticlesMutationVariables,
  APITypes.DeleteArticlesMutation
>;
export const createChatRoom = /* GraphQL */ `mutation CreateChatRoom(
  $input: CreateChatRoomInput!
  $condition: ModelChatRoomConditionInput
) {
  createChatRoom(input: $input, condition: $condition) {
    id
    name
    image
    Messages {
      items {
        id
        createdAt
        text
        chatroomID
        userID
        mediaS3URL
        updatedAt
        messageUserId
        __typename
      }
      nextToken
      __typename
    }
    LastMessage {
      id
      createdAt
      text
      chatroomID
      userID
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      mediaS3URL
      updatedAt
      messageUserId
      __typename
    }
    users {
      items {
        id
        chatRoomID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    isChatBot
    createdAt
    updatedAt
    chatRoomLastMessageId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChatRoomMutationVariables,
  APITypes.CreateChatRoomMutation
>;
export const updateChatRoom = /* GraphQL */ `mutation UpdateChatRoom(
  $input: UpdateChatRoomInput!
  $condition: ModelChatRoomConditionInput
) {
  updateChatRoom(input: $input, condition: $condition) {
    id
    name
    image
    Messages {
      items {
        id
        createdAt
        text
        chatroomID
        userID
        mediaS3URL
        updatedAt
        messageUserId
        __typename
      }
      nextToken
      __typename
    }
    LastMessage {
      id
      createdAt
      text
      chatroomID
      userID
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      mediaS3URL
      updatedAt
      messageUserId
      __typename
    }
    users {
      items {
        id
        chatRoomID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    isChatBot
    createdAt
    updatedAt
    chatRoomLastMessageId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChatRoomMutationVariables,
  APITypes.UpdateChatRoomMutation
>;
export const deleteChatRoom = /* GraphQL */ `mutation DeleteChatRoom(
  $input: DeleteChatRoomInput!
  $condition: ModelChatRoomConditionInput
) {
  deleteChatRoom(input: $input, condition: $condition) {
    id
    name
    image
    Messages {
      items {
        id
        createdAt
        text
        chatroomID
        userID
        mediaS3URL
        updatedAt
        messageUserId
        __typename
      }
      nextToken
      __typename
    }
    LastMessage {
      id
      createdAt
      text
      chatroomID
      userID
      User {
        id
        name
        status
        image
        userType
        deleted
        firstName
        lastName
        mobileNo
        headerImage
        pwdName
        expoNotificationToken
        nativeNotificationToken
        postRegistrationComplete
        createdAt
        updatedAt
        __typename
      }
      mediaS3URL
      updatedAt
      messageUserId
      __typename
    }
    users {
      items {
        id
        chatRoomID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    isChatBot
    createdAt
    updatedAt
    chatRoomLastMessageId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteChatRoomMutationVariables,
  APITypes.DeleteChatRoomMutation
>;
export const createMessage = /* GraphQL */ `mutation CreateMessage(
  $input: CreateMessageInput!
  $condition: ModelMessageConditionInput
) {
  createMessage(input: $input, condition: $condition) {
    id
    createdAt
    text
    chatroomID
    userID
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    mediaS3URL
    updatedAt
    messageUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMessageMutationVariables,
  APITypes.CreateMessageMutation
>;
export const updateMessage = /* GraphQL */ `mutation UpdateMessage(
  $input: UpdateMessageInput!
  $condition: ModelMessageConditionInput
) {
  updateMessage(input: $input, condition: $condition) {
    id
    createdAt
    text
    chatroomID
    userID
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    mediaS3URL
    updatedAt
    messageUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageMutationVariables,
  APITypes.UpdateMessageMutation
>;
export const deleteMessage = /* GraphQL */ `mutation DeleteMessage(
  $input: DeleteMessageInput!
  $condition: ModelMessageConditionInput
) {
  deleteMessage(input: $input, condition: $condition) {
    id
    createdAt
    text
    chatroomID
    userID
    User {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    mediaS3URL
    updatedAt
    messageUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMessageMutationVariables,
  APITypes.DeleteMessageMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    name
    status
    image
    ChatRooms {
      items {
        id
        chatRoomID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    userType
    deleted
    firstName
    lastName
    mobileNo
    headerImage
    UserExtendedInfos {
      items {
        id
        field
        value
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pwdName
    expoNotificationToken
    nativeNotificationToken
    myfriendss {
      items {
        id
        myFriendsID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    postRegistrationComplete
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    name
    status
    image
    ChatRooms {
      items {
        id
        chatRoomID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    userType
    deleted
    firstName
    lastName
    mobileNo
    headerImage
    UserExtendedInfos {
      items {
        id
        field
        value
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pwdName
    expoNotificationToken
    nativeNotificationToken
    myfriendss {
      items {
        id
        myFriendsID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    postRegistrationComplete
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    name
    status
    image
    ChatRooms {
      items {
        id
        chatRoomID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    userType
    deleted
    firstName
    lastName
    mobileNo
    headerImage
    UserExtendedInfos {
      items {
        id
        field
        value
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pwdName
    expoNotificationToken
    nativeNotificationToken
    myfriendss {
      items {
        id
        myFriendsID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    postRegistrationComplete
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createMyFriendsUser = /* GraphQL */ `mutation CreateMyFriendsUser(
  $input: CreateMyFriendsUserInput!
  $condition: ModelMyFriendsUserConditionInput
) {
  createMyFriendsUser(input: $input, condition: $condition) {
    id
    myFriendsID
    userID
    myFriends {
      id
      status
      Users {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    user {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMyFriendsUserMutationVariables,
  APITypes.CreateMyFriendsUserMutation
>;
export const updateMyFriendsUser = /* GraphQL */ `mutation UpdateMyFriendsUser(
  $input: UpdateMyFriendsUserInput!
  $condition: ModelMyFriendsUserConditionInput
) {
  updateMyFriendsUser(input: $input, condition: $condition) {
    id
    myFriendsID
    userID
    myFriends {
      id
      status
      Users {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    user {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMyFriendsUserMutationVariables,
  APITypes.UpdateMyFriendsUserMutation
>;
export const deleteMyFriendsUser = /* GraphQL */ `mutation DeleteMyFriendsUser(
  $input: DeleteMyFriendsUserInput!
  $condition: ModelMyFriendsUserConditionInput
) {
  deleteMyFriendsUser(input: $input, condition: $condition) {
    id
    myFriendsID
    userID
    myFriends {
      id
      status
      Users {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    user {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMyFriendsUserMutationVariables,
  APITypes.DeleteMyFriendsUserMutation
>;
export const createUserChatRoom = /* GraphQL */ `mutation CreateUserChatRoom(
  $input: CreateUserChatRoomInput!
  $condition: ModelUserChatRoomConditionInput
) {
  createUserChatRoom(input: $input, condition: $condition) {
    id
    chatRoomID
    userID
    chatRoom {
      id
      name
      image
      Messages {
        nextToken
        __typename
      }
      LastMessage {
        id
        createdAt
        text
        chatroomID
        userID
        mediaS3URL
        updatedAt
        messageUserId
        __typename
      }
      users {
        nextToken
        __typename
      }
      isChatBot
      createdAt
      updatedAt
      chatRoomLastMessageId
      __typename
    }
    user {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserChatRoomMutationVariables,
  APITypes.CreateUserChatRoomMutation
>;
export const updateUserChatRoom = /* GraphQL */ `mutation UpdateUserChatRoom(
  $input: UpdateUserChatRoomInput!
  $condition: ModelUserChatRoomConditionInput
) {
  updateUserChatRoom(input: $input, condition: $condition) {
    id
    chatRoomID
    userID
    chatRoom {
      id
      name
      image
      Messages {
        nextToken
        __typename
      }
      LastMessage {
        id
        createdAt
        text
        chatroomID
        userID
        mediaS3URL
        updatedAt
        messageUserId
        __typename
      }
      users {
        nextToken
        __typename
      }
      isChatBot
      createdAt
      updatedAt
      chatRoomLastMessageId
      __typename
    }
    user {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserChatRoomMutationVariables,
  APITypes.UpdateUserChatRoomMutation
>;
export const deleteUserChatRoom = /* GraphQL */ `mutation DeleteUserChatRoom(
  $input: DeleteUserChatRoomInput!
  $condition: ModelUserChatRoomConditionInput
) {
  deleteUserChatRoom(input: $input, condition: $condition) {
    id
    chatRoomID
    userID
    chatRoom {
      id
      name
      image
      Messages {
        nextToken
        __typename
      }
      LastMessage {
        id
        createdAt
        text
        chatroomID
        userID
        mediaS3URL
        updatedAt
        messageUserId
        __typename
      }
      users {
        nextToken
        __typename
      }
      isChatBot
      createdAt
      updatedAt
      chatRoomLastMessageId
      __typename
    }
    user {
      id
      name
      status
      image
      ChatRooms {
        nextToken
        __typename
      }
      userType
      deleted
      firstName
      lastName
      mobileNo
      headerImage
      UserExtendedInfos {
        nextToken
        __typename
      }
      pwdName
      expoNotificationToken
      nativeNotificationToken
      myfriendss {
        nextToken
        __typename
      }
      postRegistrationComplete
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserChatRoomMutationVariables,
  APITypes.DeleteUserChatRoomMutation
>;
