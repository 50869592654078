import React, { useEffect, useState, useCallback } from 'react';
import './moderation.css';
import Share from '../../components/share/Share';
import ModeratePost from '../../components/moderatePost/ModeratePost';
import { API, graphqlOperation } from 'aws-amplify';
import { listCommunityPosts } from '../../graphql/queries'; // Adjust this import to your project structure

export default function Moderation({ role }) {
  const [posts, setPosts] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchPosts();
  }, []);

  
  const fetchPosts = async (token = null) => {
    setIsLoading(true);
    try {
      const limit = 7;
      const filter = {
        needsModeration: {
          eq: true // Adjust based on your schema, assuming 'eq' is used for equality
        }
      };
      const postData = await API.graphql(
        graphqlOperation(listCommunityPosts, { filter, limit, nextToken: token })
      );
  
      if (postData.data.listCommunityPosts) {
        const newItems = postData.data.listCommunityPosts.items || [];
        const newNextToken = postData.data.listCommunityPosts.nextToken;
  
        setPosts(prevPosts => (token ? [...prevPosts, ...newItems] : newItems));
        setNextToken(newNextToken);
      }
    } catch (error) {
      console.error('Error fetching posts', error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleScroll = useCallback(() => {
    if (isLoading || !nextToken) return;

    if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
      fetchPosts(nextToken);
    }
  }, [isLoading, nextToken]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const removePost = (postId) => {
    setPosts(prevPosts => prevPosts.filter(post => post.id !== postId));
  };

  return (
    <div className='feed'>
      <div className="feedWrapper">
        <Share role={role} />

        {posts.length > 0 ? (
          posts.map((p) => <ModeratePost key={p.id} post={p} removePost={removePost} />)
        ) : (
          <p>Be the first to post!</p>
        )}
        {isLoading && <p>Loading more posts...</p>}
      </div>
    </div>
  );
}
