import React, { useState, useEffect, useContext } from 'react';
import "./post.css";
import { MoreVert, Delete } from "@mui/icons-material";
import S3Image from '../S3Image/S3Image';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { createCommunityLikes, deleteCommunityLikes, createCommunityPostPollVotes, deleteCommunityPostPollVotes, createCommunityComment, deleteCommunityPost } from '../../graphql/mutations';
import { listGuidanceContents } from '../../graphql/queries';
import { getCommunityPostPollVotes, getCommunityLikes } from '../../graphql/queries';
import { UserContext } from '../../App';
import { getCommunityPostExpanded } from './queries';
import SendIcon from '@mui/icons-material/Send';
import { Menu, MenuItem, IconButton, Snackbar, Dialog, DialogContent, DialogTitle, Button, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';




export default function Post({ post: initialPost, removePost }) {
  const [post, setPost] = useState(initialPost);
  const [anchorEl, setAnchorEl] = useState(null);
  const [like, setLike] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const userData = useContext(UserContext);
  const [pollVotes, setPollVotes] = useState(post?.CommunityPostPoll?.items.map(item => item.CommunityPostPollVotes.items.length) || []);
  const [userVotes, setUserVotes] = useState(post?.CommunityPostPoll?.items.map(item => item.CommunityPostPollVotes.items.find(vote => vote.communityPostPollVotesUserId === userData.id)) || []);
  const [newComment, setNewComment] = useState('');
  const [showAllComments, setShowAllComments] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [guidanceContent, setGuidanceContent] = useState('');


  const navigate = useNavigate();

  const navigateToUserProfile = (userId) => {
    //navigate(`/profile/${userId}`);
  };

  //console.log(post);

  const handleGuidanceClick = async () => {
    //console.log(post.communityPostGuidanceId);
    const response = await API.graphql(
      graphqlOperation(listGuidanceContents, {
        filter: { guidanceID: { eq: post.communityPostGuidanceId } }
      })
    )
    const data = response.data.listGuidanceContents.items[0]; // Assuming the items hold the actual list
    //console.log(data);
    setGuidanceContent(data);

    setModalOpen(true);
  };


  useEffect(() => {
    setLike(post.CommunityLikes.items.length);

    if (post?.mediaS3Key && post?.mediaS3Key.endsWith('.mp4')) {
      (async () => {
        const url = await Storage.get(post.mediaS3Key);
        //console.log(url);
        setMediaUrl(url);
      })();
    }
  }, [post]);

  const likeHandler = async () => {
    try {
      const userLike = post.CommunityLikes.items.find((like) => like.communityLikesUserId === userData.id);

      setLike(userLike ? like - 1 : like + 1);
      setIsLiked(!userLike);

      if (userLike) {
        // User has already liked this post, so we proceed to unlike it
        await API.graphql(graphqlOperation(deleteCommunityLikes, { input: { id: userLike.id } }));
      } else {
        // User has not liked this post yet, so we proceed to like it
        await API.graphql(graphqlOperation(createCommunityLikes, { input: { communitypostID: post.id, communityLikesUserId: userData.id } }));
      }

      // Fetch the single post again to update the local state with the fresh data
      const response = await API.graphql(graphqlOperation(getCommunityPostExpanded, { id: post.id }));

      const updatedPost = response.data.getCommunityPost; // Get the first item from the items array


      // Update the local state with the fresh data
      setPost(updatedPost);
      setLike(updatedPost.CommunityLikes.items.length);
      setIsLiked(!!updatedPost.CommunityLikes.items.find(item => item.communityLikesUserId === userData.id));

    } catch (error) {
      console.error('Error handling like', error);
    }
  };



  const handlePollVote = async (pollId, index) => {
    try {
      const userVote = userVotes[index];

      if (userVote) {
        // User has voted on this poll option before, delete the vote
        await API.graphql(graphqlOperation(deleteCommunityPostPollVotes, { input: { id: userVote.id } }));
        const updatedPollVotes = [...pollVotes];
        updatedPollVotes[index] -= 1;
        setPollVotes(updatedPollVotes);

        const updatedUserVotes = [...userVotes];
        updatedUserVotes[index] = null;
        setUserVotes(updatedUserVotes);
      } else {
        // User has not voted on this poll option before, add a new vote
        const newVote = {
          input: {
            communitypostpollID: pollId,
            communityPostPollVotesUserId: userData.id,
          }
        };
        const response = await API.graphql(graphqlOperation(createCommunityPostPollVotes, newVote));

        const updatedPollVotes = [...pollVotes];
        updatedPollVotes[index] += 1;
        setPollVotes(updatedPollVotes);

        // Fetch the new vote data to update the userVotes state
        const newVoteData = await API.graphql(graphqlOperation(getCommunityPostPollVotes, { id: response.data.createCommunityPostPollVotes.id }));
        const updatedUserVotes = [...userVotes];
        updatedUserVotes[index] = newVoteData.data.getCommunityPostPollVotes;
        setUserVotes(updatedUserVotes);
      }

    } catch (error) {
      console.error('Error registering vote', error);
    }
  };

  const handleAddComment = async () => {
    try {
      // You need to implement the createCommunityComment mutation in your GraphQL API
      await API.graphql(graphqlOperation(createCommunityComment, { input: { communitypostID: post.id, text: newComment, communityCommentUserId: userData.id } }));

      // Clear the comment input after successfully sending a comment
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment', error);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeletePost = async () => {
    try {
      await API.graphql(graphqlOperation(deleteCommunityPost, { input: { id: post.id } }));
      removePost(post.id);
      setShowSnackbar(true);
    } catch (error) {
      console.error('Error deleting post', error);
    }
    handleMenuClose();
  };

  return (
    <div className="post">
      <div className="postWrapper">
        <div className="postTop">
          <div className="postTopLeft" onClick={() => navigateToUserProfile(post.User.id)}>
            {post?.User?.image ? (
              <S3Image S3Key={post?.User?.image} S3height={128} className="postProfileImg" />
            ) : (
              <img
                className="postProfileImg"
                src={post?.User?.image || 'assets/persons/default.png'}
                alt={`${post.User.firstName} ${post.User.lastName}`}
              />
            )}
            <span className="postUsername">
              {post?.User?.firstName ? `${post.User.firstName} ${post.User.lastName}` : post.User.name}
            </span>
            <span className="postDate">
              {new Date(post.dateTime).toLocaleString()}
            </span>
          </div>
          <div className="postTopRight">
            {userData.userType === 'ACECURA_ADMIN' ? (
              <>
                <IconButton aria-label="more" aria-haspopup="true" onClick={handleMenuOpen}>
                  <MoreVert />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleDeletePost}>
                    <Delete /> Delete
                  </MenuItem>
                </Menu>
              </>
            ) : null}
          </div>
        </div>
        <div className="postCenter">
          <span className="postText">{post.text}</span>
          <br />
          {post.communityPostGuidanceId && post.Guidance ? (
            <div className="guidanceWrapper" style={{ cursor: 'pointer' }} onClick={handleGuidanceClick}>
              <h3>{post.Guidance.title}</h3>
              <p>{post.Guidance.shortDesc}</p>
              {post.Guidance.S3ObjectKey && (
                <S3Image S3Key={post.Guidance.S3ObjectKey} S3height={350} className="guidanceImg" alt="" />
              )}
              <p className="guidanceLink">Click to read full article</p>
            </div>
          ) : (
            <>
              {(mediaUrl && post?.mediaS3Key?.endsWith('.mp4')) ? (
                <video controls className="postVideo" alt="" style={{ maxHeight: '450px' }}>
                  <source src={mediaUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <>
                  {post?.mediaS3Key && !post.mediaS3Key.endsWith('.mp4') ? (
                    <S3Image S3Key={post.mediaS3Key} S3height={350} className="postImg" alt="" />
                  ) : null}
                </>
              )}
            </>
          )}



          {post?.CommunityPostPoll?.items?.map((pollItem, index) => (
            <div key={index} className="sharePollOption" onClick={() => handlePollVote(pollItem.id, index)}>
              <span className="sharePollOptionInput">{pollItem.text}</span>
              <span className="sharePollOptionCount">{pollVotes[index]} votes</span>
            </div>
          ))}

        </div>
        <div className="postBottom">
          <div className="postBottomLeft">
            <img className="likeIcon" src="/assets/like.png" onClick={likeHandler} alt="" />
            <img className="likeIcon" src="/assets/heart.png" onClick={likeHandler} alt="" />
            <span className="postLikeCounter">{like} people like it</span>
          </div>
          <div className="postBottomRight">
            <span className="postCommentText">{post.CommunityComments.items.length} comments</span>
          </div>
        </div>

        <div className="postCommentsSection">
          {post.CommunityComments.items.slice(0, showAllComments ? undefined : 2).map((comment, index) => (
            <div key={index} className="postCommentItem">
              <img
                src={comment?.User?.image || 'assets/persons/default.png'}
                alt={`${comment.User.firstName} ${comment.User.lastName}`}
                className="postCommentUserImage"
                onClick={() => navigateToUserProfile(comment.User.id)}
              />
              <div className="postCommentContent">
                <span className="postCommentUserName" onClick={() => navigateToUserProfile(comment.User.id)}>{comment.User.firstName ? `${comment.User.firstName} ${comment.User.lastName}` : comment.User.name} </span>
                <span className="postCommentText">{comment.text}</span>
              </div>
            </div>
          ))}
          {post.CommunityComments.items.length > 2 && (
            <span
              onClick={() => setShowAllComments(prev => !prev)}
              className="postCommentShowButton"
              style={{ color: '#00B5E2', cursor: 'pointer', textDecoration: 'underline' }}
            >
              {showAllComments ? 'Hide comments' : 'Show all comments'}
            </span>
          )}
        </div>

        <div className="postCommentBox">
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment..."
            className="postCommentInput"
          />
          <SendIcon onClick={handleAddComment} className="postCommentSendIcon" style={{ color: '#00B5E2' }} />
        </div>

      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        message="Post deleted successfully"
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => setShowSnackbar(false)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />

      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="guidance-modal-title"
        fullWidth
        maxWidth="lg"
        style={{ width: '80%', height: '80%', margin: 'auto', overflow: 'hidden' }} // Add overflow hidden to ensure dialog respects boundaries
      >
        <DialogTitle id="guidance-modal-title" style={{ paddingBottom: 0, background: '#f0f0f0' }}>
          {post.Guidance?.title}
          <IconButton
            aria-label="close"
            onClick={() => setModalOpen(false)}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers style={{ overflow: 'auto', paddingTop: 0 }}>
          {post.Guidance?.S3ObjectKey && (
            <S3Image S3Key={post.Guidance.S3ObjectKey} S3height={350} className="guidanceImg" alt="" />
          )}
          <div style={{ marginTop: 20, marginBottom: 20, padding: '0 24px' }}>
            <p style={{ textAlign: 'justify' }}>{post.Guidance?.longDesc}</p>
          </div>
          <hr style={{ marginBottom: 20 }} />
          <div dangerouslySetInnerHTML={{ __html: guidanceContent?.content || '' }} style={{ padding: '0 24px' }} />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between', padding: '8px 24px', background: '#f0f0f0' }}>
          <Button onClick={() => console.log("Open in new window")}>Open in new window</Button>
          <Button onClick={() => console.log("Share with a friend")}>Share with a friend</Button>
          <Button onClick={() => console.log("Email for later")}>Email for later</Button>
        </DialogActions>
      </Dialog>


    </div>
  );
}
