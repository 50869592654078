import React, { useState, useContext } from 'react';
import './sidebar.css';
import { RssFeed, Chat, Group, HelpOutline, Event, Settings } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { UserContext } from '../../App';
import S3Image from '../S3Image/S3Image';

export default function Sidebar() {
    const [showAdminSubList, setShowAdminSubList] = useState(false);
    const [showGroupsSubList, setShowGroupsSubList] = useState(false);
    const userData = useContext(UserContext);

    const toggleAdminSubList = () => {
        setShowAdminSubList(!showAdminSubList);
    };

    const toggleGroupsSubList = () => {
        setShowGroupsSubList(!showGroupsSubList);
    };

    return (
        <div className='sidebar'>
            <div className="sidebarWrapper">
                <ul className="sidebarList">
                    <li className="sidebarListItem">
                        <Link to="/" className="sidebarLink">
                            <RssFeed className='sidebarIcon' />
                            <span className="sidebarListItemText">Feed</span>
                        </Link>
                    </li>
                    <li className="sidebarListItem">
                        <Chat className='sidebarIcon' />
                        <span className="sidebarListItemText">Chats</span>
                    </li>
                    <li className="sidebarListItem" onClick={toggleGroupsSubList}>
                        <Group className='sidebarIcon' />
                        <span className="sidebarListItemText">Groups</span>
                    </li>
                    {showGroupsSubList && userData && userData.groups && (
                        <>
                            <ul className="sidebarSubList">
                                {userData.groups.map(group => (
                                    <Link to={`/group/${group.groupId}`} key={group.groupId} className="sidebarLink" style={{ marginBottom: '10px' }}>
                                        {group?.headerS3Key ? (
                                            <S3Image S3Key={group.headerS3Key} S3height={64} className="sidebarGroupImg" />
                                        ) : (
                                            <img
                                                className="sidebarGroupImg"
                                                src='assets/persons/icon-group-blue.png'
                                            />
                                        )}
                                        <li className="sidebarSubListItem">
                                            {group.name}
                                        </li>
                                    </Link>
                                ))}
                            </ul>
                            <div className="padBottom">&nbsp;</div>
                        </>
                    )}
                    <li className="sidebarListItem">
                        <HelpOutline className='sidebarIcon' />
                        <span className="sidebarListItemText">Questions</span>
                    </li>
                    <li className="sidebarListItem">
                        <Event className='sidebarIcon' />
                        <span className="sidebarListItemText">Events</span>
                    </li>
                    {userData && userData.userType === "ACECURA_ADMIN" && (
                        <>
                            <li className="sidebarListItem" onClick={toggleAdminSubList}>
                                <Settings className='sidebarIcon' />
                                <span className="sidebarListItemText">Administration</span>
                            </li>
                            {showAdminSubList && (
                                <ul className="sidebarSubList">
                                    <li className="sidebarSubListItem">Users</li>
                                    <Link to="/admin-groups" className="sidebarLink">
                                        <li className="sidebarSubListItem">Groups</li>
                                    </Link>
                                    <Link to="/moderation" className="sidebarLink">
                                    <li className="sidebarSubListItem">Moderation</li>
                                    </Link>
                                </ul>
                            )}
                        </>
                    )}
                </ul>
                <hr className='sidebarHr' />
            </div>
        </div>
    )
}
