import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { createCommunityUserNotifications } from '../../graphql/mutations'; // Adjust the import path as necessary
import './InviteUser.css';
import S3Image from '../S3Image/S3Image';

const InviteUser = ({ user, groupID }) => {

    const handleInvite = async () => {
        const notificationInput = {
            communityUserNotificationsUserId: user.id,
            communityUserNotificationsCommunityGroupId: groupID,
            NotificationType: "GROUP_INVITE",
            seen: false
        };

        try {
            await API.graphql(graphqlOperation(createCommunityUserNotifications, { input: notificationInput }));
            //console.log(`User with ID: ${user.id} invited to group ID: ${groupID}`);
        } catch (error) {
            console.error("Error inviting user:", error);
        }
    };

    const displayName = user.firstName ? `${user.firstName} ${user.lastName}` : user.name;

    return (
        <div className="invite-user-item">
            {user?.image ? (
                <S3Image S3Key={user.image} S3height={128} className="invite-user-image" />
            ) : (
                <img
                    src='/assets/persons/default.png'
                    alt={`${user.firstName} ${user.lastName}`}
                    className="invite-user-image"
                />
            )}
            <div className="invite-user-name">{displayName}</div>
            <button onClick={handleInvite} className="invite-button">Invite</button>
        </div>

    );
};

export default InviteUser;
