import React from 'react';
import './NotificationItem.css';
import { API, graphqlOperation } from 'aws-amplify';
import { createCommunityUserGroupRoles, deleteCommunityUserNotifications } from '../../graphql/mutations';

const NotificationItem = ({ notification, refreshNotifications }) => {



    const handleJoin = async () => {

        const input = {
            userGroupRole: "USER",
            communityUserGroupRolesUserId: notification.User.id,
            communityUserGroupRolesCommunityGroupId: notification.CommunityGroup.id,
        };

        try {
            const response = await API.graphql(graphqlOperation(createCommunityUserGroupRoles, { input }));
            // Additional logic after successful join

            try {
                const response = await API.graphql(graphqlOperation(deleteCommunityUserNotifications, { input: { id: notification.id } }));
                await refreshNotifications();
            } catch (error) {
                console.error('Error deleting notificiation invite:', error);
                // Error handling
            }

        } catch (error) {
            console.error('Error joining group:', error);
            // Error handling
        }
    };
    const handleDecline = async () => {

        try {
            const response = await API.graphql(graphqlOperation(deleteCommunityUserNotifications, { input: { id: notification.id } }));
            await refreshNotifications();
        } catch (error) {
            console.error('Error declining invite:', error);
            // Error handling
        }
    };

    const renderGroupInvite = () => (
        <div className="notificationItem">
            You have been invited to join {notification.CommunityGroup.name}
            <button onClick={handleJoin}>Join</button>
            <button onClick={handleDecline}>Decline</button>
        </div>
    );

    switch (notification.NotificationType) {
        case "GROUP_INVITE":
            return renderGroupInvite();
        default:
            return null;
    }
};

export default NotificationItem;