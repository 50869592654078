import React, { useState, useContext } from 'react';
import "./share.css";
import { PermMedia, Label, Description } from "@mui/icons-material";
import BallotIcon from '@mui/icons-material/Ballot';
import DeleteIcon from '@mui/icons-material/Delete';
import { API, graphqlOperation } from 'aws-amplify';
import { Storage } from 'aws-amplify';
import { createCommunityPost, createCommunityPostPoll } from '../../graphql/mutations';
import { UserContext } from '../../App';
import { v4 as uuidv4 } from 'uuid';
import AddArticle from '../addArticle/AddArticle';
import S3Image from '../S3Image/S3Image';


export default function Share({ role, groupId }) {
  const [inputValue, setInputValue] = useState("");
  const [media, setMedia] = useState(null);
  const [mediaPreview, setMediaPreview] = useState(null);
  const [pollOptions, setPollOptions] = useState([]);
  const userData = useContext(UserContext);
  const [showAddArticleModal, setShowAddArticleModal] = useState(false);
  const [newGuidanceID, setNewGuidanceID] = useState(null);
  const [guidanceTitle, setGuidanceTitle] = useState(null);
  const defaultGroupId = 'ba70f770-cd78-4ba3-93e2-36f39a598f82'; 
  const [selectedGroupId, setSelectedGroupId] = useState(groupId || defaultGroupId);

  const handleContentSubmission = (guidanceID, title) => {
    setNewGuidanceID(guidanceID);
    setGuidanceTitle(title);

    toggleAddArticleModal(); // Close the modal
    //console.log("New guidance ID:", guidanceID); // For debugging or further use
  };


  //console.log(userData);

  const fileInputRef = React.useRef();

  const toggleAddArticleModal = () => {
    //console.log("showing");
    setShowAddArticleModal(!showAddArticleModal);
  };

  const handleMediaUpload = async (e) => {
    const file = e.target.files[0];
    setMedia(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setMediaPreview(reader.result);
    };
    reader.readAsDataURL(file);
  }

  const handleAddPollOption = () => {
    setPollOptions([...pollOptions, '']);
  }

  const handleRemovePollOption = (index) => {
    setPollOptions(pollOptions.filter((_, i) => i !== index));
  }

  const handlePollOptionChange = (index, value) => {
    const newPollOptions = [...pollOptions];
    newPollOptions[index] = value;
    setPollOptions(newPollOptions);
  }

  const handleShare = async () => {
    try {
      let mediaKey = '';
      //console.log(newGuidanceID);

      if (media) {
        const ext = media.name.split('.').pop();
        mediaKey = `community/media/${uuidv4()}.${ext}`;
        await Storage.put(mediaKey, media);
      }

      const currentDateTime = new Date().toISOString();
      const newPost = {
        input: {
          text: inputValue,
          communityPostUserId: userData.id,
          dateTime: currentDateTime,
          mediaS3Key: media ? mediaKey : null,
          //communitygroupID: "8da642c5-f0f9-4ed8-8fc8-d4bfab56a5fa",
          communitygroupID: selectedGroupId,
          communityPostGuidanceId: newGuidanceID
        },
      };

      console.log(newPost);
      const result = await API.graphql(graphqlOperation(createCommunityPost, newPost));
      //console.log('Post created', result);

      const communityPostId = result.data.createCommunityPost.id;

      for (let option of pollOptions) {
        const pollInput = {
          input: {
            text: option,
            communitypostID: communityPostId,
          },
        };
        await API.graphql(graphqlOperation(createCommunityPostPoll, pollInput));
      }
      //console.log('Poll options added');

      setMedia(null);
      setMediaPreview(null);
      setPollOptions([]);
      setInputValue('');
    } catch (error) {
      console.error('Error creating post', error);
    }
  };


  return (
    <div className='share'>
      <div className="shareWrapper">
        <div className="shareTop">
          {userData?.image ? (
            <S3Image S3Key={userData.image} S3height={128} className="shareProfileImg" />
          ) : (
            <img className='shareProfileImg' src='assets/persons/default.png' alt="" />
          )}

          <input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="What's on your mind?"
            className='shareInput'
          />
        </div>
        <hr className='shareHr' />

        {mediaPreview &&
          <div className="shareMediaPreviewWrapper">
            <img src={mediaPreview} alt="media preview" className="shareMediaPreview" />
          </div>
        }
        {pollOptions.length > 0 && pollOptions.map((option, index) => (
          <div key={index} className="sharePollOption">
            <input
              value={option}
              onChange={(e) => handlePollOptionChange(index, e.target.value)}
              placeholder={`Option ${index + 1}`}
              className='sharePollOptionInput'
            />
            <DeleteIcon onClick={() => handleRemovePollOption(index)} style={{ cursor: 'pointer', marginLeft: '10px' }} />
          </div>
        ))}


        <div className="shareBottom">
          {newGuidanceID ? (
            <div className="guidanceDisplay">Guidance Article: {guidanceTitle}</div>
          )
            : (
              <>
                <div className="shareOptions">
                  <div className="shareOption">
                    <PermMedia htmlColor='tomato' className='shareIcon' onClick={() => fileInputRef.current.click()} />
                    <span className='shareOptionText' onClick={() => fileInputRef.current.click()}>Photo or video</span>
                    <input
                      type="file"
                      accept="image/*,video/*"
                      onChange={handleMediaUpload}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                  </div>
                </div>
                <div className="shareOptions">
                  <div className="shareOption">
                    <BallotIcon htmlColor='#00B5E2' className='shareIcon' onClick={handleAddPollOption} />
                    <span className='shareOptionText' onClick={handleAddPollOption}>Add Poll Option</span>
                  </div>
                </div>
                {role === "ADMIN" || role === "OWNER" ? (
                  <div className="shareOptions">
                    <div className="shareOption" onClick={toggleAddArticleModal}>
                      <Description htmlColor='goldenrod' className='shareIcon' />
                      <span className='shareOptionText'>Article</span>
                    </div>
                  </div>
                ) : null}

              </>
            )}
          <button onClick={handleShare} className='shareButton'>Share</button>
        </div>

      </div>
      {showAddArticleModal && <AddArticle
        closeModal={toggleAddArticleModal}
        onContentSubmit={handleContentSubmission}
      />}
    </div>

  )
}