import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listCommunityGroups } from '../../graphql/queries';
import { createCommunityGroup } from '../../graphql/mutations';
import { Link } from 'react-router-dom'; // Import Link from React Router
import SettingsIcon from '@mui/icons-material/Settings';

import "./adminGroups.css";

export default function AdminGroups() {
    const [groups, setGroups] = useState([]);
    const [newGroup, setNewGroup] = useState({ name: '', description: '', imageS3Key: '', headerS3Key: '' });

    useEffect(() => {
        fetchGroups();
    }, []);

    const fetchGroups = async () => {
        try {
            const groupData = await API.graphql(graphqlOperation(listCommunityGroups));
            setGroups(groupData.data.listCommunityGroups.items);
        } catch (err) {
            console.log('error fetching groups', err);
        }
    };

    const createGroup = async () => {
        try {
            if (!newGroup.name || !newGroup.description) return;
            await API.graphql(graphqlOperation(createCommunityGroup, { input: newGroup }));
            setNewGroup({ name: '', description: '', imageS3Key: '', headerS3Key: '' });
            fetchGroups();
        } catch (err) {
            console.log('error creating group', err);
        }
    };

    return (
        <div className="admin-container">
            <div className="create-group">
                <input
                    type="text"
                    placeholder="Group Name"
                    value={newGroup.name}
                    onChange={e => setNewGroup({ ...newGroup, name: e.target.value })}
                />
                <textarea
                    placeholder="Description"
                    value={newGroup.description}
                    onChange={e => setNewGroup({ ...newGroup, description: e.target.value })}
                />
                <button onClick={createGroup}>Create Group</button>
            </div>
            <div className="group-list">
                {groups.map(group => (
                    <div key={group.id} className="group">
                        <div className="group-info">
                            <h3>{group.name}</h3>
                            <p>{group.description}</p>
                        </div>
                        {/* Use Link to navigate to a new page */}
                        <Link to={`/manage-group/${group.id}`} className="manage-button">
                            <SettingsIcon /> Manage
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}
