import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { API, Storage } from 'aws-amplify';
import { updateCommunityGroup } from '../../graphql/mutations'; // Adjust the import as necessary
import { getCommunityGroup } from '../../graphql/queries'; // Import the query
import "./Group.css";
import Feed from "../../components/feed/Feed.jsx";
import { UserContext } from '../../App';
import S3Image from '../../components/S3Image/S3Image';

export default function Group() {
    const { groupId } = useParams();
    const userData = useContext(UserContext);
    const [headerImageUploadComplete, setHeaderImageUploadComplete] = useState(false);
    const [headerS3Key, setHeaderS3Key] = useState(""); 
    const [groupDetails, setGroupDetails] = useState(""); 


    useEffect(() => {
        // Function to fetch community group details
        const fetchCommunityGroup = async () => {
            try {
                const { data } = await API.graphql({
                    query: getCommunityGroup,
                    variables: { id: groupId },
                });
                const response = data.getCommunityGroup;
                if (response.headerS3Key) {
                    setHeaderS3Key(response.headerS3Key); // Set the header image S3 key
                }
                setGroupDetails(response);
            } catch (error) {
                console.error('Error fetching community group:', error);
            }
        };

        fetchCommunityGroup();
    }, [groupId]); // This effect depends on `groupId`

    const getUserRoleInGroup = () => {
        if (userData && userData.groups) {
            const group = userData.groups.find(group => group.groupId === groupId);
            return group ? group.role : "No Role";
        }
        return "No Role";
    };

    const userRole = getUserRoleInGroup();

    const handleHeaderImageChange = async (e) => {
        const file = e.target.files[0];
        if (file && (userRole === "ADMIN" || userRole === "OWNER")) {
            const key = `community/${uuidv4()}-${file.name}`;
            await Storage.put(key, file, { contentType: file.type });

            // After successful upload, update the community group headerS3Key
            const input = {
                id: groupId,
                headerS3Key: key,
            };

            try {
                await API.graphql({
                    query: updateCommunityGroup,
                    variables: { input },
                });
                setHeaderImageUploadComplete(true); // Indicate upload completion
                setHeaderS3Key(key); // Update local state with new header image S3 key
            } catch (error) {
                console.error('Error updating community group:', error);
            }
        }
    };

    return (
        <>
            <div className="profile">
                <div className="profileRight">
                    <div className="profilerightTop">
                        {userRole === "ADMIN" || userRole === "OWNER" ? (
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleHeaderImageChange}
                                style={{ marginBottom: '10px' }}
                            />
                        ) : null}
                        <div className="profileCover">
                            {/* Use S3Image component to display the header image */}
                            <S3Image S3Key={headerS3Key} S3height={350} className="profileCoverImg" alt="" />
                        </div>
                        <div className="profileInfo">
                            <h2 className='profileInfoName'>{groupDetails.name}</h2>
                        </div>
                    </div>
                    <div className="profilerightBottom">
                        <Feed groupId={groupId} role={userRole} />
                    </div>
                </div>
            </div>
        </>
    );
}
