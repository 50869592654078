import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { getCommunityGroup, listUsers, listCommunityUserGroupRoles } from '../../graphql/queries'; // Import the query to fetch a single group
import { updateCommunityGroup, updateCommunityUserGroupRoles } from '../../graphql/mutations'; // Import the mutation to update a group
import InviteUser from '../../components/inviteUser/InviteUser';
import './manageGroup.css';

export default function ManageGroup() {
    const { groupId } = useParams();
    const [group, setGroup] = useState({ name: '', description: '', imageS3Key: '', headerS3Key: '' });
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentUsers, setCurrentUsers] = useState([]);

    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const sortedUsers = React.useMemo(() => {
        let sortableUsers = [...currentUsers];
        if (sortConfig !== null) {
            sortableUsers.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableUsers;
    }, [currentUsers, sortConfig]);

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };


    useEffect(() => {
        fetchGroup();
        fetchUsers();
        fetchGroupUsers();

    }, []);

    const fetchGroup = async () => {
        try {
            const groupData = await API.graphql(graphqlOperation(getCommunityGroup, { id: groupId }));
            setGroup(groupData.data.getCommunityGroup);
        } catch (err) {
            console.log('error fetching group', err);
        }
    };
    const fetchGroupUsers = async () => {
        try {
            const filter = { communityUserGroupRolesCommunityGroupId: { eq: groupId } };
            const userData = await API.graphql(graphqlOperation(listCommunityUserGroupRoles, { filter: filter, limit: 100 }));
            const userRoles = userData.data.listCommunityUserGroupRoles.items;

            // Include the association ID in your mapping
            const usersMapped = userRoles.map(role => ({
                associationId: role.id, // This is the key change
                id: role.User.id,
                firstName: role.User.firstName,
                lastName: role.User.lastName,
                role: role.userGroupRole,
                status: role.User.status,
            }));

            setCurrentUsers(usersMapped);
        } catch (err) {
            console.log('error fetching users', err);
        }
    };


    const fetchUsers = async () => {
        try {
            const userData = await API.graphql(graphqlOperation(listUsers));
            setUsers(userData.data.listUsers.items);
        } catch (err) {
            console.log('error fetching users', err);
        }
    };

    const updateGroup = async () => {
        try {
            if (!group.name || !group.description) {
                console.log("Group name and description are required.");
                return;
            }

            // Construct the update object
            const groupUpdate = {
                id: groupId,
                name: group.name,
                description: group.description
                // Include other fields if needed
            };

            await API.graphql(graphqlOperation(updateCommunityGroup, { input: groupUpdate }));
            console.log("Group updated successfully.");

            // Optional: Fetch the updated group data or handle post-update UI changes
        } catch (err) {
            console.log('error updating group', err);
        }
    };

    const handleRoleChange = (associationId, newRole) => {
        console.log("Association ID: ", associationId, "New Role:", newRole, "Group ID: ", groupId);
        const userRoleUpdate = async () => {
            try {
                const input = {
                    id: associationId, // Use the association ID here
                    userGroupRole: newRole,
                    // You might not need to pass the userId and groupId again if you're updating by the association ID
                };

                await API.graphql(graphqlOperation(updateCommunityUserGroupRoles, { input }));
                console.log("User role updated successfully.");

                fetchGroupUsers(); // Refresh the users list
            } catch (err) {
                console.error('error updating user role', err);
            }
        };

        userRoleUpdate();
    };



    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredUsers = users.filter(user =>
        `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="admin-container">
            <div className="group-section">
                <h2>Group Information</h2>
                <div className="create-group">
                    <input
                        type="text"
                        placeholder="Group Name"
                        value={group.name}
                        onChange={e => setGroup({ ...group, name: e.target.value })}
                    />
                    <textarea
                        placeholder="Description"
                        value={group.description}
                        onChange={e => setGroup({ ...group, description: e.target.value })}
                    />
                    <button onClick={updateGroup}>Update Group</button>
                </div>
            </div>

            <div className="user-section">
                <h2>Current Users</h2>
                <div className="user-list">
                    <table>
                        <thead>
                            <tr>
                                <th onClick={() => requestSort('firstName')}>Name</th>
                                <th onClick={() => requestSort('role')}>Role</th>
                                <th onClick={() => requestSort('status')}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedUsers.map(user => (
                                <tr key={user.associationId}>
                                    <td>{user.firstName} {user.lastName}</td>
                                    <td>
                                        <select value={user.role} onChange={(e) => handleRoleChange(user.associationId, e.target.value)}>
                                            <option value="USER">USER</option>
                                            <option value="ADMIN">ADMIN</option>
                                            <option value="OWNER">OWNER</option>
                                            <option value="GUEST">GUEST</option>
                                        </select>
                                    </td>
                                    <td>{user.status}</td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                </div>
            </div>

            <div className="invite-section">
                <h2>Invite Users</h2>

                <div className="search-container">
                    <input
                        type="text"
                        placeholder="Search Users"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>

                <div className="user-invite-list">
                    {filteredUsers.map(user => (
                        <InviteUser key={user.id} user={user} groupID={groupId} />
                    ))}
                </div>
            </div>
        </div>
    );
}
