export const Users = [
    {
      id:1,
      profilePicture: "assets/persons/1.jpg",
      username: "Sofia jackson",
    },
    {
      id:2,
      profilePicture: "assets/persons/2.jpg",
      username: "Janell Shrum",
    },
    {
      id:3,
      profilePicture: "assets/persons/3.jpg",
      username: "Alex Durden",
    },
    {
      id:4,
      profilePicture: "assets/persons/4.jpg",
      username: "Dora Hawks",
    },
    {
      id:5,
      profilePicture: "assets/persons/5.jpg",
      username: "Thomas Holden",
    },
    {
      id:6,
      profilePicture: "assets/persons/6.jpg",
      username: "Shirley Beauchamp",
    },
    {
      id:7,
      profilePicture: "assets/persons/7.jpg",
      username: "Travis Bennett",
    },
    {
      id:8,
      profilePicture: "assets/persons/8.jpg",
      username: "Kristen Thomas",
    },
    {
      id:9,
      profilePicture: "assets/persons/9.jpg",
      username: "Gary Duty",
    },
    {
      id:10,
      profilePicture: "assets/persons10.jpg",
      username: "Angela Kocaoglu",
    },
  ];

  export const Posts = [
    {
      id: 1,
      desc: "Love For All, Hatred For None.",
      photo: "assets/post/1.jpg",
      date: "5 mins ago",
      userId: 1,
      like: 32,
      comment: 9,
    },
    {
      id: 2,
      desc: "Beautiful Day indeed.",
      photo: "assets/post/2.jpg",
      date: "15 mins ago",
      userId: 2,
      like: 2,
      comment: 1,
    },
    {
      id: 3,
      desc: "Every moment is a fresh beginning.",
      photo: "assets/post/3.jpg",
      date: "1 hour ago",
      userId: 3,
      like: 61,
      comment: 2,
    },
    {
      id: 4,
      desc: "Love for food .",
      photo: "assets/post/4.jpg",
      date: "4 hours ago",
      userId: 4,
      like: 7,
      comment: 3,
    },
    {
      id: 5,
      desc: "It's everywhere",
      photo: "assets/post/5.jpg",
      date: "5 hours ago",
      userId: 5,
      like: 23,
      comment: 5,
    },
    {
      id: 6,
      desc: "Hatred For None.",
      photo: "assets/post/1.jpg",
      date: "1 day ago",
      userId: 6,
      like: 44,
      comment: 6,
    },
    {
      id: 7,
      desc: "Never regret anything that made you smile.",
      photo: "assets/post/2.jpg",
      date: "2 days ago",
      userId: 7,
      like: 52,
      comment: 3,
    },
    {
      id: 8,
      desc: "Love For All",
      photo: "assets/post/3.jpg",
      date: "3 days ago",
      userId: 8,
      like: 15,
      comment: 1,
    },
    {
      id: 9,
      desc: "Change the world by being yourself.",
      photo: "assets/post/4.jpg",
      date: "5 days ago",
      userId: 9,
      like: 11,
      comment: 2,
    },
    {
      id: 10,
      desc: "Love Love Love",
      photo: "assets/post/5.jpg",
      date: "1 week ago",
      userId: 10,
      like: 104,
      comment: 12,
    },
  ];