export const listCommunityPostsExpanded = /* GraphQL */ `
query MyQuery($communitygroupID: ID!, $nextToken: String, $limit: Int) {
  communityPostsByDate(communitygroupID: $communitygroupID, sortDirection: DESC, limit: $limit, nextToken: $nextToken) {
    items {
      id
      text
      mediaS3Key
      dateTime
      communitygroupID
      communityPostUserId
      Guidance {
        id
        title
        shortDesc
        longDesc
        S3ObjectKey
        GuidanceContents {
          items {
            content
          }
        }
      }
      communityPostGuidanceId
      User {
        id
        firstName
        lastName
        name
        image
      }
      CommunityPostPoll(sortDirection: DESC) {
        items {
          id
          text
          communitypostID
          CommunityPostPollVotes {
            items {
              id
              communitypostpollID
              communityPostPollVotesUserId
              User {
                id
                firstName
                lastName
                name
                image
              }
            }
          }
        }
      }
      CommunityLikes {
        items {
          id
          communitypostID
          communityLikesUserId
          User {
            id
            firstName
            lastName
            name
            image
          }
        }
      }
      CommunityComments(sortDirection: DESC) {
        items {
          id
          text
          communitypostID
          communityCommentUserId
          User {
            id
            firstName
            lastName
            name
            image
          }
        }
      }
    }
    nextToken
  }
}
`