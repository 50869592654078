import React from 'react';
import { Image } from 'react';
import { Buffer } from 'buffer';

const S3Image = ({ S3Key, S3height, ...imageProps }) => {
  const S3Bucket = "acecuraapp-storage180506-staging";
  const serverlessImageURL = "https://d3cewdc4bug237.cloudfront.net/";

  const imageRequest = JSON.stringify({
    "bucket": S3Bucket,
    "key": "public/" + S3Key,
    "edits": {
      "resize": {
        "height": S3height,
        "fit": "contain"
      }
    }
  });

  const encoded = Buffer.from(imageRequest).toString('base64');

  return (
    <img src={serverlessImageURL + encoded} {...imageProps} />
  );
};

export default S3Image;
