export const getCommunityPostExpanded = /* GraphQL */ `
query MyQuery($id: ID = "") {
  getCommunityPost(id: $id) {
    id
    text
    mediaS3Key
    dateTime
    communityPostUserId
    Guidance {
      id
      title
      shortDesc
      longDesc
      S3ObjectKey
      GuidanceContents {
        items {
          content
        }
      }
    }
    communityPostGuidanceId
    User {
      firstName
      lastName
      image
      name
    }
    CommunityLikes {
      items {
        id
        communityLikesUserId
        User {
          firstName
          lastName
          image
          name
        }
      }
    }
    CommunityComments {
      items {
        text
        communityCommentUserId
        User {
          firstName
          lastName
          image
          name
        }
      }
    }
    CommunityPostPoll {
      items {
        id
        text
        CommunityPostPollVotes {
          items {
            id
            communityPostPollVotesUserId
            communitypostpollID
          }
        }
      }
    }
  }
}
`