import React, { useEffect, useState, useCallback } from 'react';
import './feed.css';
import Share from '../share/Share';
import Post from '../post/Post';
import { API, graphqlOperation } from 'aws-amplify';
import { listCommunityPostsExpanded } from './queries';

export default function Feed({ groupId, role }) {
  const [posts, setPosts] = useState([]);
  const defaultGroupId = 'ba70f770-cd78-4ba3-93e2-36f39a598f82'; 
  const [selectedGroupId, setSelectedGroupId] = useState(groupId || defaultGroupId);
  const [nextToken, setNextToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  //console.log("feed role: ", role);

  useEffect(() => {
    // This effect now depends on groupId prop to re-fetch posts when groupId changes
    setSelectedGroupId(groupId || defaultGroupId);
    fetchPosts(groupId || defaultGroupId);
  }, [groupId]);

  const fetchPosts = async (groupId, token = null) => {
    setIsLoading(true);
    try {
      const limit = 7;
      const postData = await API.graphql(
        graphqlOperation(listCommunityPostsExpanded, { communitygroupID: groupId, limit, nextToken: token })
      );

      const newItems = postData.data.communityPostsByDate.items;
      //console.log(newItems);
      const newNextToken = postData.data.communityPostsByDate.nextToken;

      //console.log('Fetched Posts:', newItems);
      //console.log('Next Token:', newNextToken);

      // Update posts based on the presence of nextToken
      setPosts(prevPosts => (token ? [...prevPosts, ...newItems] : newItems));
      setNextToken(newNextToken);
    } catch (error) {
      console.error('Error fetching posts', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScroll = useCallback(() => {
    if (isLoading || !nextToken) return;

    if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
      fetchPosts(selectedGroupId, nextToken);
    }
  }, [isLoading, nextToken, selectedGroupId]);



  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const removePost = (postId) => {
    // Remove the post with the specified postId from the posts state
    setPosts(prevPosts => prevPosts.filter(post => post.id !== postId));
  };

  return (
    <div className='feed'>
      <div className="feedWrapper">
        <Share role={role} groupId={groupId} />

        {posts.length > 0 ? (
          posts.map((p) => <Post key={p.id} post={p} removePost={removePost} />) // Pass the removePost function to Post component
        ) : (
          <p>Be the first to post!</p>
        )}
        {isLoading && <p>Loading more posts...</p>}
      </div>
    </div>
  );
}