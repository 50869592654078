import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import "./profile.css";
import Feed from "../../components/feed/Feed.jsx";
import Rightbar from "../../components/rightbar/Rightbar.jsx";

export default function Profile() {
    // Use useParams hook to access the userId parameter from the URL
    const { userId } = useParams();

    // Use useEffect to log the userId when the component mounts or the userId changes
    useEffect(() => {
        console.log("User ID:", userId);
    }, [userId]);

    return (
        <>

            <div className="profile">

                <div className="profileRight">
                    <div className="profilerightTop">
                        <div className="profileCover">
                            <img className='profileCoverImg' src="assets/post/3.jpg" alt="" />
                            <img className='profileUserImg' src="assets/persons/4.jpg" alt="" />
                        </div>
                        <div className="profileInfo">
                            <h4 className='profileInfoName'>Stephen Myburgh</h4>
                            <span className='profileInfoDesc'>Hello my friends</span>
                        </div>
                    </div>
                    <div className="profilerightBottom">
                        <Rightbar profile/>
                    </div>
                </div>
            </div>
        </>
    );
}
